import React from 'react';
import { Icon } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import styles from './PolicyDocumentError.module.scss';
import messages from './PolicyDocumentError.messages';

export default function PolicyDocumentError() {
    const translator = useTranslator();
    return (
        <div>
            <Icon icon="mi-warning" className={styles.exclamationIcon} />
            <span>{translator(messages.unableToAccessDocument)}</span>
        </div>
    );
}
