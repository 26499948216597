import React, { useState, useCallback, useMemo } from 'react';
import _ from 'lodash';
import { useModal } from '@jutro/components';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { PolicyChange, messages as commonMessage } from 'gw-capability-policychange-common-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { messages as platformMessages } from 'gw-platform-translations';
import metadata from './DriversPage.metadata.json5';
import styles from '../../PAPolicyChange.module.scss';
import messages from './DriversPage.messages';

const unassignedDrivers = 'lobData.personalAuto.coverables.unassignedDrivers';
const unassignedVehicles = 'lobData.personalAuto.coverables.unassignedVehicles';
const drivers = 'lobData.personalAuto.coverables.drivers';
const vehicles = 'lobData.personalAuto.coverables.vehicles';
function DriversPage(props) {
    const {
        showAlert
    } = useModal();

    const translator = useTranslator();
    const [driverPath, setDriverPath] = useState('');
    const [selectedDriver, setSelectedDriver] = useState({});
    const { wizardData, updateWizardData, wizardSnapshot } = props;
    const { submissionVM } = wizardData;
    const [isVmIntitialized, updateIsVmInitialized] = useState(false);
    const { EndorsementService } = useDependencies('EndorsementService');
    const {
        onValidate,
        initialValidation,
        isComponentValid,
        disregardFieldValidation
    } = useValidation('PAPolicyChangeDriverPage');
    const { authHeader } = useAuthentication();

    const cleanUnassigned = useCallback(() => {
        const unassignedVehiclesList = _.get(submissionVM.value, unassignedVehicles, []);
        const unassignedDriversList = _.get(submissionVM.value, unassignedDrivers, []);
        if (!unassignedDriversList || unassignedDriversList.length === 0) {
            _.set(submissionVM, 'lobData.personalAuto.coverables.value.unassignedDrivers', []);
        }
        if (!unassignedVehiclesList || unassignedVehiclesList.length === 0) {
            _.set(submissionVM, 'lobData.personalAuto.coverables.value.unassignedVehicles', []);
        }
    }, [submissionVM]);

    const writeValue = useCallback(
        (value, path) => {
            _.set(submissionVM, path, value);
            updateWizardData(wizardData);
        },
        [submissionVM, updateWizardData, wizardData]
    );

    const showLicenseGroupModal = useCallback(
        (driverGroup) => {
            return showAlert({
                title: messages.licenseNotUnique,
                message: translator(messages.licenceMatchMsg, {
                    driver: driverGroup[0].displayName || driverGroup[0].getDisplayName()
                }),
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: platformMessages.ok
            }).catch(_.noop);
        },
        [translator]
    );

    const uniqueLicenceNumber = useCallback(() => {
        let licenseCheckError = false;
        const driverList = _.get(submissionVM.value, 'lobData.personalAuto.coverables.drivers');
        const groupedDrivers = _.groupBy(driverList, (driver) => {
            return driver.licenseNumber;
        });
        Object.keys(groupedDrivers).forEach((driver) => {
            if (groupedDrivers[driver].length > 1) {
                showLicenseGroupModal(groupedDrivers[driver]);
                licenseCheckError = true;
                return false;
            }
            return true;
        });
        return licenseCheckError;
    }, [showLicenseGroupModal, submissionVM.value]);

    const onNext = useCallback(async () => {
        if (!uniqueLicenceNumber()) {
            if (!submissionVM.value.validate()) {
                updateWizardData(wizardData);
                return false;
            }
            submissionVM.value.beforeSave();
            const policyChangeResponse = await EndorsementService.saveEndorsement(
                [submissionVM.value],
                authHeader
            );
            submissionVM.value = new PolicyChange(policyChangeResponse);
            cleanUnassigned();
            return wizardData;
        }
        return false;
    }, [
        authHeader,
        EndorsementService,
        cleanUnassigned,
        submissionVM.value,
        wizardData,
        updateWizardData,
        uniqueLicenceNumber
    ]);
    const deleteDriver = useCallback(
        (driver, index) => {
            submissionVM.value.lobData.personalAuto.coverables.removeDriver(driver);
            disregardFieldValidation(`driver${index}`);
            submissionVM.value.updateUnAssignedMapping();
            updateWizardData(wizardData);
        },
        [disregardFieldValidation, submissionVM.value, updateWizardData, wizardData]
    );
    const hideDriverDetails = useCallback(
        (driversList, index) => {
            if (!isComponentValid) {
                if (_.get(driversList.value[index], 'tempID')) {
                    deleteDriver(driversList.value[index], index);
                }
                if (_.get(driversList.value[index], 'fixedId')) {
                    const prevValue = _.get(
                        wizardSnapshot,
                        'submissionVM.lobData.personalAuto.coverables.drivers.children'
                    ).find(
                        (driver) => driver.value.fixedId === _.get(driversList.value[index], 'fixedId')
                    );
                    _.set(
                        submissionVM,
                        `lobData.personalAuto.coverables.drivers.children[${index}].value`,
                        prevValue.value
                    );
                    updateWizardData(wizardData);
                }
                disregardFieldValidation(`driver${index}`);
            }
            setDriverPath('');
        },
        [
            deleteDriver,
            disregardFieldValidation,
            isComponentValid,
            submissionVM,
            updateWizardData,
            wizardData,
            wizardSnapshot
        ]
    );

    const showDriverDetails = useCallback((vehicle, index) => {
        setDriverPath(`lobData.personalAuto.coverables.drivers.children[${index}]`);
        setSelectedDriver(vehicle);
    }, []);

    const removeDriver = useCallback(
        (driversList, driver) => {
            const driverIndex = driversList.value.findIndex(
                (driverFromList) => _.isEqual(driverFromList, driver)
            );
            deleteDriver(driver, driverIndex);
        },
        [deleteDriver]
    );

    const getVehicleFromList = useCallback((vehicleList, VehicleDriverID) => {
        const vehicleID = _.includes(VehicleDriverID, '_')
            ? VehicleDriverID.split('_')[1]
            : VehicleDriverID;
        return vehicleList.value.find((vehicle) => vehicle.fixedId === Number(vehicleID));
    }, []);

    const toggleDriverAssignment = useCallback(
        (driver, vehicle) => {
            if (
                submissionVM.value.lobData.personalAuto.coverables.isDriverAssigned(driver, vehicle)
            ) {
                submissionVM.value.lobData.personalAuto.coverables.removeDriverAssignment(
                    driver,
                    vehicle
                );
            } else {
                submissionVM.value.lobData.personalAuto.coverables.assignDriver(driver, vehicle);
            }
            submissionVM.value.updateUnAssignedMapping();
            updateWizardData(wizardData);
        },
        [submissionVM.value, updateWizardData, wizardData]
    );

    const unassignedVehiclesOverrides = useCallback(() => {
        const unassignedVehiclesList = _.get(submissionVM.value, unassignedVehicles, []);
        let overrides = [];
        if (!_.isEmpty(unassignedVehiclesList)) {
            overrides = unassignedVehiclesList.map((vehicle, index) => {
                return {
                    [`errorNotificationVehicle${index}`]: {
                        message: translator(commonMessage.noVehicleError, {
                            vehicle: vehicle.getDisplayName()
                        }),
                        visible: vehicle.getDisplayName()
                    }
                };
            });
        }
        return Object.assign({}, ...overrides);
    }, [submissionVM, translator]);

    const unassignedDriversOverrides = useCallback(() => {
        const unassignedDriversList = _.get(submissionVM.value, unassignedDrivers, []);
        let overrides = [];
        if (!_.isEmpty(unassignedDriversList)) {
            overrides = unassignedDriversList.map((driver, index) => {
                return {
                    [`errorNotificationDriver${index}`]: {
                        message: translator(commonMessage.noDriverError, {
                            driver: driver.getDisplayName()
                        }),
                        visible: driver.getDisplayName()
                    }
                };
            });
        }
        return Object.assign({}, ...overrides);
    }, [submissionVM, translator]);

    const getAssignedVehicleID = useCallback(
        (driver) => {
            const driverVehicleIDs = submissionVM.value.lobData.personalAuto.coverables
                .getAssignedVehicles(driver);
            return driverVehicleIDs.map(
                (v) => `${driver.fixedId || driver.tempID}_${v.fixedId.toString()}`
            );
        },
        [submissionVM.value.lobData.personalAuto.coverables]
    );

    const handleValueChange = useCallback(
        (value, driver, vehicleList) => {
            if (
                driver.tempID === selectedDriver.tempID
                || driver.fixedId === selectedDriver.fixedId
            ) {
                if (value.length) {
                    const driverID = selectedDriver.fixedId || selectedDriver.tempID;
                    const assignedVehicleDriver = _.get(
                        submissionVM.value,
                        'lobData.personalAuto.coverables.vehicleDrivers'
                    );
                    _.remove(assignedVehicleDriver, (vehicleDrivers) => {
                        return (
                            vehicleDrivers.driver.fixedId === driverID
                            || vehicleDrivers.driver.tempID === driverID
                        );
                    });
                    value.forEach((element) => {
                        toggleDriverAssignment(driver, getVehicleFromList(vehicleList, element));
                    });
                } else {
                    const vehicle = submissionVM.value.lobData.personalAuto.coverables
                        .getAssignedVehicles(driver);
                    toggleDriverAssignment(driver, vehicle[0]);
                }
                cleanUnassigned();
            }
        },
        [
            selectedDriver,
            cleanUnassigned,
            submissionVM.value,
            toggleDriverAssignment,
            getVehicleFromList
        ]
    );

    const driverOverrides = useMemo(() => {
        const availableDriverPath = 'lobData.personalAuto.coverables.drivers.children';
        const driversList = _.get(submissionVM, drivers, []);
        const vehicleList = _.get(submissionVM, vehicles, []);
        let overrides = [];
        if (!_.isEmpty(driversList)) {
            overrides = driversList.value.map((driver, index) => {
                const driverName = driver.getDisplayName();
                const vehicleInfo = vehicleList.value.map((vehicle) => {
                    return {
                        code: `${driver.fixedId || driver.tempID}_${vehicle.fixedId.toString()}`,
                        name: vehicle.displayName
                    };
                });
                return {
                    [`paDriverContainer${index}`]: {
                        visible: `${availableDriverPath}[${index}]` === driverPath
                    },
                    [`paDriverSectionNameId${index}`]: {
                        content: driverName || translator(messages.paNewDriver)
                    },
                    [`paDriverSectionTrashId${index}`]: {
                        disabled:
                            driversList.length === 1
                            || (`${availableDriverPath}[${index}]` !== driverPath && !isComponentValid),
                        onClick: () => {
                            removeDriver(driversList, driver);
                        }
                    },
                    [`paDriverSectionEditId${index}`]: {
                        disabled:
                            `${availableDriverPath}[${index}]` !== driverPath && !isComponentValid,
                        visible: `${availableDriverPath}[${index}]` !== driverPath,
                        onClick: () => {
                            showDriverDetails(driver, index);
                        }
                    },
                    [`cancelBtnId${index}`]: {
                        onClick: () => {
                            hideDriverDetails(driversList, index);
                        }
                    },
                    [`vehicleAssignment${index}`]: {
                        availableValues: vehicleInfo,
                        value: getAssignedVehicleID(driver),
                        onValueChange: (value) => handleValueChange(value, driver, vehicleList)
                    }
                };
            });
        }
        return Object.assign({}, ...overrides);
    }, [
        submissionVM,
        driverPath,
        translator,
        isComponentValid,
        getAssignedVehicleID,
        removeDriver,
        showDriverDetails,
        hideDriverDetails,
        handleValueChange
    ]);

    const setDefaultNewEntry = useCallback(() => {
        const driver = submissionVM.value.lobData.personalAuto.coverables.createDriver();
        setSelectedDriver(driver);
    }, [submissionVM.value.lobData.personalAuto.coverables, setSelectedDriver]);

    const onAddNew = useCallback(() => {
        setDefaultNewEntry();
        const newVehicleIndex = submissionVM.lobData.personalAuto.coverables.drivers.length - 1;
        setDriverPath(`lobData.personalAuto.coverables.drivers.children[${newVehicleIndex}]`);
        _.set(
            submissionVM.value,
            `lobData.personalAuto.coverables.drivers[${newVehicleIndex}].accidents`,
            '0'
        );
        _.set(
            submissionVM.value,
            `lobData.personalAuto.coverables.drivers[${newVehicleIndex}].violations`,
            '0'
        );
        updateWizardData(wizardData);
    }, [setDefaultNewEntry, submissionVM, updateWizardData, wizardData]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onAddDriverClick: onAddNew,
            onValidate: onValidate
        }
    };

    const overrideProps = {
        '@field': {
            showOptional: true,
            phoneWide: {
                labelPosition: 'top'
            }
        },
        addDriver: {
            disabled: !isComponentValid
        },
        ...driverOverrides,
        ...unassignedVehiclesOverrides(),
        ...unassignedDriversOverrides()
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [submissionVM, overrideProps]
    );

    if (!isVmIntitialized) {
        updateWizardData(wizardData);
        updateIsVmInitialized(true);
        cleanUnassigned();
        return null;
    }

    return (
        <WizardPage
            onNext={onNext}
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
            cancelLabel={(appConfig.persona === 'policyholder' ? commonMessage.cancelAllChanges : commonMessage.cancel)}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onValueChange={writeValue}
                callbackMap={resolvers.resolveCallbackMap}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

DriversPage.propTypes = wizardProps;
export default DriversPage;
