import _ from 'lodash';
import { EntityUtil } from 'gw-portals-util-js';
// eslint-disable-next-line import/no-unresolved
import LocaleConfig from 'locale-config';
import Address from './Address';

const { name: nameConfig } = LocaleConfig;

function append(sb, fieldId, value) {
    if (!_.isEmpty(value) && _.includes(nameConfig.visibleFields, fieldId)) {
        sb.push(value);
    }
}

function format(person, delimiter) {
    const lastName = person.lastNameKanji || person.lastName;
    const firstName = person.firstNameKanji || person.firstName;
    const result = [];
    switch (nameConfig.formatMode) {
        case 'default':
            append(result, 'FirstName', firstName);
            append(result, 'LastName', lastName);

            break;
        case 'France':
            append(result, 'LastName', lastName);
            append(result, 'FirstName', firstName);
            break;
        case 'Japan':
            append(result, 'LastName', lastName);
            append(result, 'FirstName', firstName);
            break;
        case 'China':
            append(result, 'LastName', lastName);
            append(result, 'FirstName', firstName);
            break;
        default:
            break;
    }
    return result.join(delimiter);
}

function Contact(data) {
    this.subtype = 'Person';
    if (data) {
        _.extend(this, data);
    }
}

Contact.prototype.getDisplayName = function (defaultName) {
    return format(this, ' ') || defaultName;
};

Contact.prototype.initializePrimaryAddress = function (data) {
    const addressData = _.extend({}, data, { tempID: EntityUtil.nextId() });
    this.primaryAddress = new Address(addressData);
};

export default Contact;
