import React, {
    useCallback, useMemo, useState
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useTranslator } from '@jutro/locale';
import { MetadataContent } from '@jutro/uiconfig';
import { useAuthentication, AUTH_ERRORS } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import AuthMessageComponent from '../AuthMessageComponent/AuthMessageComponent';
import { checkEmailValidity } from '../LoginUtil';

import metadata from './SignUpComponent.metadata.json5';
import messages from './SignUpComponent.messages';
import styles from './SignUpComponent.module.scss';

function SignUpComponent(props) {
    const translator = useTranslator();
    const [pageView, setPageView] = useState();
    const [pageError, updatePageError] = useState();
    const [formData, updateFormData] = useState({});
    const { signUp, login } = useAuthentication();
    const { onValidate, isComponentValid } = useValidation('SignUpComponent');
    const { history, successPath, onBackToSignIn } = props;

    const handleSignUp = useCallback(
        (evt) => {
            if (evt) {
                evt.preventDefault();
            }

            if (!isComponentValid) {
                updatePageError(AUTH_ERRORS.fieldEmpty);
                return Promise.resolve();
            }

            const email = _.get(formData, 'emailAddress');
            const password = _.get(formData, 'password');
            const confirmPassword = _.get(formData, 'confirmPassword');

            if (!checkEmailValidity(email)) {
                updatePageError('invalidEmail');
                return Promise.resolve();
            }
            if (password !== confirmPassword) {
                updatePageError('passwordsNotMatching');
                return Promise.resolve();
            }

            return signUp({
                givenName: _.get(formData, 'firstName'),
                familyName: _.get(formData, 'lastName'),
                userName: email,
                email: email,
                password: password
            })
                .then(() => {
                    login({
                        username: email,
                        password: password
                    }).then(
                        () => {
                            // redirect user to home view
                            history.push(successPath);
                        },
                        () => {
                            setPageView(AUTH_ERRORS.autoLoginError);
                        }
                    );
                })
                .catch((err) => {
                    switch (err.error) {
                        case AUTH_ERRORS.userAlreadyExists:
                            setPageView(AUTH_ERRORS.userAlreadyExists);
                            break;
                        default:
                            setPageView(AUTH_ERRORS.invalidAttempt);
                            break;
                    }
                });
        },
        [formData, history, isComponentValid, login, signUp, successPath]
    );

    const readValue = useCallback((id, path) => _.get(formData, path), [formData]);

    const writeValue = useCallback(
        (value, path) => {
            const nextFormData = _.cloneDeep(formData);
            _.set(nextFormData, path, value);
            updateFormData(nextFormData);
        },
        [formData]
    );

    const overrideProps = useMemo(
        () => ({
            '@field': {
                onValueChange: writeValue
            },
            signUpContainer: {
                visible: _.isUndefined(pageView)
            },
            errorMsg: {
                visible: !_.isUndefined(pageError),
                message: translator(messages[pageError])
            },
            emailAlreadyExistsError: {
                visible: pageView === AUTH_ERRORS.userAlreadyExists
            },
            invalidAttemptError: {
                visible: pageView === AUTH_ERRORS.invalidAttempt
            },
            unableToLoginAutomaticallyError: {
                visible: pageView === AUTH_ERRORS.autoLoginError
            },
            signUpButton: {
                onTrigger: handleSignUp
            }
        }),
        [handleSignUp, pageError, pageView, translator, writeValue]
    );

    const resolvers = useMemo(
        () => ({
            resolveValue: readValue,
            resolveCallbackMap: {
                handleBackToSignIn: onBackToSignIn
            },
            resolveComponentMap: {
                authmessagecomponent: AuthMessageComponent
            },
            resolveClassNameMap: styles
        }),
        [onBackToSignIn, readValue]
    );

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            onValidationChange={onValidate}
            {...resolvers}
        />
    );
}

SignUpComponent.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    successPath: PropTypes.string.isRequired,
    onBackToSignIn: PropTypes.func
};

SignUpComponent.defaultProps = {
    onBackToSignIn: undefined
};

export default withRouter(SignUpComponent);
