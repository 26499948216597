import { setComponentMapOverrides } from '@jutro/uiconfig';
import FNOLHOWizard from './FNOLHOWizard';
import FNOLHOTypeOfIncidentPage from './pages/TypeOfIncident/TypeOfIncidentPage';
import FNOLFireDamagePage from './pages/FireDamage/FireDamagePage';
import FNOLWaterDamagePage from './pages/WaterDamage/WaterDamagePage';
import FNOLCriminalDamagePage from './pages/CriminalDamage/CriminalDamagePage';

setComponentMapOverrides(
    {
        FNOLHOWizard: { component: 'FNOLHOWizard' },
        FNOLHOTypeOfIncidentPage: { component: 'FNOLHOTypeOfIncidentPage' },
        FNOLFireDamagePage: { component: 'FNOLFireDamagePage' },
        FNOLWaterDamagePage: { component: 'FNOLWaterDamagePage' },
        FNOLCriminalDamagePage: { component: 'FNOLCriminalDamagePage' }
    },
    {
        FNOLHOWizard,
        FNOLHOTypeOfIncidentPage,
        FNOLFireDamagePage,
        FNOLWaterDamagePage,
        FNOLCriminalDamagePage
    }
);

export {
    FNOLHOWizard as default
};
