import React, {
    useContext, useCallback, useEffect, useState
} from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { isCapabilityEnabled } from 'gw-portals-config-js';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { CreditCardUtil } from 'gw-portals-util-js';
import message from '../../../PolicyChangeCommon.messages';
import styles from './PaymentPage.module.scss';
import metadata from './PaymentPage.metadata.json5';
import messages from '../PaymentPage.messages';

const { capabilitiesConfig } = appConfig;

const PAYMENT_METHOD = {
    bank: 'wire',
    credit: 'creditcard'
};

const PaymentTypePayInFull = {
    code: 'payInfull',
    name: messages.hoPaymentPayInFull
};
const PaymentTypeRedistribute = {
    code: 'redistribute',
    name: messages.hoPaymentRedistribute
};

export function PaymentPage(props) {
    const {
        wizardData,
        updateWizardData,
        history
    } = props;
    const { authHeader } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const { EndorsementService } = useDependencies('EndorsementService');
    const { BillingService } = useDependencies('BillingService');
    const { submissionVM, bindData } = wizardData;
    const [isVMInitialised, updateIsVMInitialised] = useState(false);
    const [showPremiumDecrease, setShowPremiumDecrease] = useState(false);
    const [showRedistribute, setShowRedistribute] = useState(false);
    const [paymentDifferenceCost, setPaymentDifferenceCost] = useState(0);
    const [unpaidInvoicesExist, updateUnpaidInvoicesExist] = useState(false);
    const {
        isComponentValid,
        initialValidation,
        registerComponentValidation
    } = useValidation('PAPolicyChangePaymentPage');

    const initialiseVM = useCallback(() => {
        const paymentInitialData = {
            paymentMethod: PAYMENT_METHOD.bank,
            bankAccountData: {}
        };
        const paymentsDataViewModel = viewModelService.create(
            paymentInitialData,
            'pc',
            'edge.capabilities.policyjob.binding.dto.PaymentDetailsDTO'
        );
        _.set(wizardData, 'bindData.paymentType', undefined);
        _.set(wizardData, 'paymentsDataVM', paymentsDataViewModel);
    }, [viewModelService, wizardData]);

    useEffect(() => {
        if (isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'billing' })) {
            BillingService.getPolicyBillingSummary(submissionVM.value.policyNumber, authHeader)
                .then((policyBillingSummary) => {
                    if (
                        policyBillingSummary.unpaidInvoices
                        && policyBillingSummary.unpaidInvoices.length > 0
                    ) {
                        updateUnpaidInvoicesExist(true);
                    }
                })
                .catch((err) => {
                    console.error('Error checking policy details', err);
                });
        }
        // It should call once when page is render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!_.isEmpty(submissionVM)) {
            if (!isVMInitialised) {
                initialiseVM();
                updateIsVMInitialised(true);
            }
            const transactionAmount = _.get(submissionVM, 'transactionCost.amount.value');
            setPaymentDifferenceCost(transactionAmount);
            if (transactionAmount <= 0) {
                _.set(wizardData, 'paymentsDataVM', []);
                setShowPremiumDecrease(true);
            }
        }
    }, [setShowPremiumDecrease, submissionVM, initialiseVM, isVMInitialised, wizardData]);

    const handleChange = useCallback(
        (value, path) => {
            if (value === 'redistribute') {
                setShowRedistribute(true);
            } else {
                setShowRedistribute(false);
            }
            _.set(wizardData, path, value);
            updateWizardData(wizardData);
        },
        [setShowRedistribute, updateWizardData, wizardData]
    );

    const getAvailablePaymentType = useCallback(() => {
        if (_.get(submissionVM, 'transactionCost.amount.value') > 0) {
            if (unpaidInvoicesExist) {
                return [PaymentTypePayInFull, PaymentTypeRedistribute];
            }
            return [PaymentTypePayInFull];
        }
        return [];
    }, [submissionVM, unpaidInvoicesExist]);

    const isModelValid = useCallback((paymentViewModel) => {
        return paymentViewModel.aspects
            ? paymentViewModel.aspects.valid && paymentViewModel.aspects.subtreeValid : false;
    }, []);

    const checkCostAndFormInitialized = useCallback(() => {
        const { paymentsDataVM } = wizardData;
        const isRedistribute = _.get(wizardData, 'bindData.paymentType') === 'redistribute';
        const transactionAmount = _.get(submissionVM, 'transactionCost.amount.value');
        let isPaymentComponentValid = false;
        if (paymentsDataVM) {
            isPaymentComponentValid = isModelValid(paymentsDataVM);
        }

        let isPaymentValid = true;
        if (transactionAmount > 0) {
            isPaymentValid = isRedistribute ? true : isPaymentComponentValid;
        }
        return isPaymentValid;
    }, [wizardData, submissionVM, isModelValid]);

    useEffect(() => {
        registerComponentValidation(checkCostAndFormInitialized);
    }, [checkCostAndFormInitialized, registerComponentValidation]);

    const handlePaymentOptionChange = useCallback(
        (value) => {
            let dataToOmit;
            const { paymentsDataVM } = wizardData;
            if (value === PAYMENT_METHOD.bank) {
                dataToOmit = 'creditCardData';
                _.set(paymentsDataVM.value, 'bankAccountData', {});
            } else {
                dataToOmit = 'bankAccountData';
                _.set(paymentsDataVM.value, 'creditCardData', {});
            }

            paymentsDataVM.value = _.omit(paymentsDataVM.value, `${dataToOmit}`);

            _.set(wizardData, 'paymentsDataVM.paymentMethod.value', value);
            updateWizardData(wizardData);
        },
        [updateWizardData, wizardData]
    );

    const onNext = useCallback(async () => {
        try {
            const { paymentsDataVM } = wizardData;
            const paymentMethod = _.get(paymentsDataVM.value, 'paymentMethod');
            const paymentType = _.get(bindData, 'paymentType');
            let paymentDetails = { paymentMethod: 'none' };
            if (paymentType === 'redistribute') {
                paymentDetails = { paymentMethod: paymentType };
            }
            if (paymentMethod === PAYMENT_METHOD.bank && paymentType === 'payInfull') {
                _.set(paymentsDataVM.value, 'creditCardData', undefined);
                paymentDetails = {
                    paymentMethod: paymentMethod,
                    bankAccountData: _.get(paymentsDataVM.value, 'bankAccountData')
                };
            } else if (paymentMethod === PAYMENT_METHOD.credit && paymentType === 'payInfull') {
                _.set(paymentsDataVM.value, 'bankAccountData', undefined);
                paymentDetails = {
                    paymentMethod: paymentMethod,
                    creditCardData: _.get(paymentsDataVM.value, 'creditCardData')
                };
            }
            const bindResponse = await EndorsementService.bind(
                [submissionVM.value.jobID, paymentDetails],
                authHeader
            );
            submissionVM.value = bindResponse.policyChange;
            _.set(wizardData, 'changesAppliedForward', bindResponse.changesAppliedForward);
            updateWizardData(submissionVM);
        } catch {
            const policyNumber = _.get(submissionVM, 'policyNumber.value');
            history.push(`/contactAgent/${policyNumber}`);
        }
        return wizardData;
    }, [
        EndorsementService,
        authHeader,
        bindData,
        history,
        submissionVM,
        updateWizardData,
        wizardData
    ]);
    const paymentMethod = _.get(wizardData, 'paymentsDataVM.paymentMethod.value');
    const paymentType = _.get(bindData, 'paymentType');
    const { paymentsDataVM } = wizardData;
    const creditCardIssuer = _.get(paymentsDataVM, 'creditCardData.creditCardIssuer.value.code');

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: 'left',
            phoneWide: {
                labelPosition: 'top'
            }
        },
        policyChange: {
            visible: paymentDifferenceCost > 0
        },
        paymentTypeRadioButton: {
            visible: paymentDifferenceCost > 0,
            onValueChange: handleChange,
            availableValues: getAvailablePaymentType()
        },
        paymentOptions: {
            visible: paymentType === 'payInfull' && paymentDifferenceCost > 0,
            onValueChange: handlePaymentOptionChange
        },
        bankAccountContainer: {
            visible:
                paymentMethod === PAYMENT_METHOD.bank
                && paymentType === 'payInfull'
                && paymentDifferenceCost > 0
        },
        creditCardContainer: {
            visible:
                paymentMethod === PAYMENT_METHOD.credit
                && paymentType === 'payInfull'
                && paymentDifferenceCost > 0
        },
        creditCardNumber: {
            mask: CreditCardUtil.getInputMaskForIssuerCode(creditCardIssuer)
        },
        policyChangePaymentsDecreaseInPremium: {
            visible: showPremiumDecrease
        },
        policyChangePaymentsRedistributeMessage: {
            visible: showRedistribute
        },
        policyPeriod: {
            labelPosition: undefined
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <WizardPage
            nextLabel={(paymentDifferenceCost > 0 ? messages.paBuy : messages.confirm)}
            cancelLabel={(appConfig.persona === 'policyholder' ? message.cancelAllChanges : message.cancel)}
            skipWhen={initialValidation}
            disableNext={!isComponentValid}
            onNext={onNext}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={wizardData}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

PaymentPage.propTypes = wizardProps;
export default withRouter(PaymentPage);
