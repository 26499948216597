import { defineMessages } from 'react-intl';

export default defineMessages({
    hoTypeOfIncident: {
        id: 'fnol.ho.views.ho-what.Type of Incident',
        defaultMessage: 'Type of Incident'
    },
    hoFireDamage: {
        id: 'fnol.ho.views.ho-what.Fire Damage',
        defaultMessage: 'Fire Damage'
    },
    hoWaterDamage: {
        id: 'fnol.ho.views.ho-what.Water Damage',
        defaultMessage: 'Water Damage'
    },
    hoCriminalDamage: {
        id: 'fnol.ho.views.ho-what.Criminal Damage',
        defaultMessage: 'Criminal Damage'
    },
    hoOtherDamage: {
        id: 'fnol.ho.views.ho-what.Other',
        defaultMessage: 'Other'
    },
    fireDamageHeader: {
        id: 'fnol.ho.views.ho-fire-details.Details (Fire Damage)',
        defaultMessage: 'Details (Fire Damage)'
    },
    fireCause: {
        id: 'fnol.ho.views.ho-fire-details.What caused the fire (if you know)?',
        defaultMessage: 'What caused the fire (if you know)?'
    },
    fireDiscovered: {
        id: 'fnol.ho.views.ho-fire-details.How was the fire discovered?',
        defaultMessage: 'How was the fire discovered?'
    },
    isHomeHabitable: {
        id: 'fnol.ho.views.ho-fire-details.Is the home habitable?',
        defaultMessage: 'Is the home habitable?'
    },
    choiceYes: {
        id: 'fnol.ho.views.ho-fire-details.Yes',
        defaultMessage: 'Yes'
    },
    choiceNo: {
        id: 'fnol.ho.views.ho-fire-details.No',
        defaultMessage: 'No'
    },
    choiceNotSure: {
        id: 'fnol.ho.views.ho-fire-details.I\'m not sure',
        defaultMessage: 'I\'m not sure'
    },
    isHomeSecure: {
        id: 'fnol.ho.views.ho-fire-details.Is the home secure',
        defaultMessage: 'Is the home secure?'
    },
    anyOtherDetails: {
        id: 'fnol.ho.views.ho-fire-details.Any other details?',
        defaultMessage: 'Any other details?'
    },
    waterDamageHeader: {
        id: 'fnol.ho.views.ho-water-details.Details (Water Damage)',
        defaultMessage: 'Details (Water Damage)'
    },
    typeOfDamage: {
        id: 'fnol.ho.views.ho-water-details.What was the type of damage?',
        defaultMessage: 'What was the type of damage?'
    },
    waterDamage: {
        id: 'fnol.ho.views.ho-water-details.Water Damage',
        defaultMessage: 'Water Damage'
    },
    moldDamage: {
        id: 'fnol.ho.views.ho-water-details.Mold',
        defaultMessage: 'Mold'
    },
    sourceOfWater: {
        id: 'fnol.ho.views.ho-water-details.What was the source of the water?',
        defaultMessage: 'What was the source of the water?'
    },
    plumbingSource: {
        id: 'fnol.ho.views.ho-water-details.Plumbing or Appliance',
        defaultMessage: 'Plumbing or Appliance'
    },
    roofLeakSource: {
        id: 'fnol.ho.views.ho-water-details.Roof Leak',
        defaultMessage: 'Roof Leak'
    },
    otherSource: {
        id: 'fnol.ho.views.ho-water-details.Other',
        defaultMessage: 'Other'
    },
    hasWaterTurnedOff: {
        id: 'fnol.ho.views.ho-water-details.Has the water been turned off?',
        defaultMessage: 'Has the water been turned off?'
    },
    waterChoiceYes: {
        id: 'fnol.ho.views.ho-water-details.Yes',
        defaultMessage: 'Yes'
    },
    waterChoiceNo: {
        id: 'fnol.ho.views.ho-water-details.No',
        defaultMessage: 'No'
    },
    waterChoiceNotSure: {
        id: 'fnol.ho.views.ho-water-details.I\'m not sure',
        defaultMessage: 'I\'m not sure'
    },
    anyOtherDetailsOfWater: {
        id: 'fnol.ho.views.ho-water-details.Any other details?',
        defaultMessage: 'Any other details?'
    },
    criminalDamageHeader: {
        id: 'fnol.ho.views.ho-crime-details.Details (Criminal Damage)',
        defaultMessage: 'Details (Criminal Damage)'
    },
    typeOfCrime: {
        id: 'fnol.ho.views.ho-crime-details.What type of crime took place?',
        defaultMessage: 'What type of crime took place?'
    },
    burglaryCrime: {
        id: 'fnol.ho.views.ho-crime-details.Burglary',
        defaultMessage: 'Burglary'
    },
    vandalismCrime: {
        id: 'fnol.ho.views.ho-crime-details.Vandalism',
        defaultMessage: 'Vandalism'
    },
    riotCrime: {
        id: 'fnol.ho.views.ho-crime-details.Riot or civil commotion',
        defaultMessage: 'Riot or civil commotion'
    },
    anyOtherDetailsCrime: {
        id: 'fnol.ho.views.ho-crime-details.Any other details',
        defaultMessage: 'Any other details?'
    },
    flowConfigErrorcreatingClaim: {
        id: 'fnol.ho.config.FlowConfig.Error creating claim',
        defaultMessage: 'Error creating claim'
    },
    homeownersClaim: {
        id: 'fnol.ho.config.FlowConfig.An error occurred attempting to create homeowners claim.',
        defaultMessage: 'An error occurred attempting to create homeowners claim.'
    },
    draftClaim: {
        id: 'fnol.ho.config.FlowConfig.Creating homeowners draft claim...',
        defaultMessage: 'Creating homeowners draft claim...'
    },
    hoDetails: {
        id: 'fnol.wizard.step.ho.Details',
        defaultMessage: 'Details'
    },
    hoCreateDraftClaim: {
        id: 'fnol.ho.config.FlowConfig.Creating homeowners draft claim...',
        defaultMessage: 'Creating homeowners draft claim...'
    },
    hoCreateDraftErrorTitle: {
        id: 'fnol.ho.config.FlowConfig.Error creating claim',
        defaultMessage: 'Error creating claim'
    },
    hoCreateDraftErrorMessage: {
        id: 'fnol.ho.config.FlowConfig.An error occurred attempting to create homeowners claim.',
        defaultMessage: 'An error occurred attempting to create homeowners claim.'
    }
});
