import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';

import {
    Icon,
    Loader,
    Link,
    withModalContext
} from '@jutro/components';
import {
    TranslatorContext,
    withIntl
} from '@jutro/locale';
import { DataTable, DisplayColumn } from '@jutro/datatable';
import { ServiceManager } from '@jutro/services';

import { ViewModelForm, withViewModelService } from 'gw-portals-viewmodel-react';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { withDependencies } from 'gw-portals-dependency-react';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { DatatableUtil } from 'gw-portals-util-js';
import { messages as commonMessages } from 'gw-platform-translations';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import ContactUtil from '../../utils/ClaimsDetailsUtil';
import ContactLinkComponent from '../../components/ContactLinkComponent/ContactLinkComponent';
import VendorLinkComponent from '../../components/VendorLinkComponent/VendorLinkComponent';
import metadata from './ClaimDetails.metadata.json5';
import CreateNotePopUp from './CreateNotePopUp';
import claimDetailsStyles from './ClaimDetails.module.scss';
import messages from '../../Claims.messages';
import './ClaimDetails.messages';

class ClaimDetails extends Component {
    static propTypes = {
        location: PropTypes.shape({
            state: PropTypes.shape({
                summarystate: PropTypes.shape({}).isRequired
            }).isRequired
        }).isRequired,
        match: PropTypes.shape({
            url: PropTypes.string,
            params: PropTypes.shape({
                claimNumber: PropTypes.string
            })
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func,
            goBack: PropTypes.func,
            location: PropTypes.shape({
                state: PropTypes.shape({})
            })
        }).isRequired,
        authHeader: PropTypes.shape({
            Authorization: PropTypes.string
        }).isRequired,
        ClaimService: PropTypes.shape({
            addRecentlyViewedClaim: PropTypes.func,
            claimsDocUploadToken: PropTypes.func,
            claimsRemoveDocument: PropTypes.func,
            createClaimNote: PropTypes.func,
            getClaimDetail: PropTypes.func,
            getClaimNotes: PropTypes.func,
            uploadDocument: PropTypes.func
        }).isRequired,
        ClaimDownloadService: PropTypes.shape({
            getClaimDocument: PropTypes.func
        }).isRequired,
        intl: PropTypes.func.isRequired
    };

    static contextType = TranslatorContext;

    localeService = ServiceManager.getService('locale-service');

    constructor(props) {
        super(props);
        this.getDocumentRemove = this.getDocumentRemove.bind(this);
    }

    state = {
        claimDetailsData: '',
        isClaimDetailsPageVisible: true,
        isLoading: true,
        coverage: 0,
        redirectPath: {},
        uploadToken: null,
        documentAlert: '',
        selectedTab: 'summaryTab'
    };

    componentDidMount() {
        const {
            history: { location: state = {} }
        } = this.props;
        const redirectPathValues = state;
        this.setState({
            redirectPath: _.get(redirectPathValues, 'state.redirectPath') || '/claims'
        });
        this.getClaimDetailsResponse();
        this.claimsDocUploadToken();
    }

    getFormattedNotesArray = (res) => {
        return res.map((note) => {
            return {
                author: note.author.firstName,
                date: note.dateCreated,
                subject: note.subject,
                note: note.body
            };
        });
    };

    getClaimDetailsResponse = () => {
        const { ClaimService, authHeader, match } = this.props;
        Promise.all([ClaimService.getClaimDetail(match.params.claimNumber, authHeader),
            ClaimService.getClaimNotes(match.params.claimNumber, authHeader)]).then(
            (response) => {
                const [claimDetails, notesResponse] = response;
                const notesArray = this.getFormattedNotesArray(notesResponse);
                const translator = this.context;
                const pa = claimDetails.lobs.personalAuto;
                if (pa && pa.vehicleIncidents[0]) {
                    pa.vehicleIncidents[0].selected = true;
                }

                const responseData = {
                    claim: claimDetails,
                    notes: notesArray,
                    claimPayments: claimDetails.checks,
                    claimServices: claimDetails.serviceRequests,
                    activities: claimDetails.activities || {},
                    reporter: this.getReportedByName(claimDetails, translator),
                    claimLosslocation: claimDetails.lossLocation,
                    selectedVehicleIncident:
                        pa && pa.vehicleIncidents[0] ? pa.vehicleIncidents[0] : {},
                    vehicles: this.getInvolvedVehicles(claimDetails),
                    claimContactsVendors: claimDetails.vendors,
                    claimHospitalsDays: claimDetails.hospitalDays,
                    claimContactsWithoutAgent: this.getPolicyTable(claimDetails)
                };

                this.setState({
                    claimDetailsData: responseData,
                    isLoading: false
                });
            }
        ).catch(() => {
            this.setState({ isLoading: false });
        });

        if (_.isFunction(ClaimService.addRecentlyViewedClaim)) {
            ClaimService.addRecentlyViewedClaim(match.params.claimNumber, authHeader);
        }
    };

    getCell = (item, index, { id: property }) => {
        return item[property];
    };

    // eslint-disable-next-line react/no-unused-class-component-methods
    getDataTableCell = (item, index, { path: property }) => {
        return item[property];
    };

    getNotesColumnCell = (item, index, { path: property }) => {
        const translator = this.context;
        let toolTipMessage = '';
        switch (property) {
            case 'author':
                toolTipMessage = translator(messages.notesAuthor);
                break;
            case 'date':
                toolTipMessage = translator(messages.notesDate);
                break;
            case 'subject':
                toolTipMessage = translator(messages.notesSubject);
                break;
            case 'note':
                toolTipMessage = translator(messages.notesContent);
                break;
            default:
                toolTipMessage = '';
        }
        return (
            <div
                style={{ whiteSpace: 'normal' }}
                className={claimDetailsStyles.gwClaimDetailsNotesWrapper}
            >
                <span title={toolTipMessage}>{item[property]}</span>
            </div>
        );
    };

    isContactTypeCheck = (contact, contactType) => {
        return ContactUtil.isInstanceOf(contact, contactType);
    };

    // eslint-disable-next-line react/no-unused-class-component-methods
    getContactTypePersonVisible = (item) => {
        return (this.isContactTypeCheck(item, 'Person') && !this.isContactTypeCheck(item, 'PersonVendor'));
    };

    getContactTypeVendorVisible = (item) => {
        return (this.isContactTypeCheck(item, 'CompanyVendor') || this.isContactTypeCheck(item, 'PersonVendor'));
    };

    getPartyNameLink = (item) => {
        const contactDTO = _.get(item, 'contactdetails');
        if (this.getContactTypeVendorVisible(item)) {
            const contact = { contactDTO };
            return <VendorLinkComponent id="vendorCell" vendors={[contact]} />;
        }
        return <ContactLinkComponent id="contactCell" contactDetails={contactDTO} />;
    };

    getRoleInvolvement = (claimContact) => {
        let roles = [];
        if (
            claimContact
            && claimContact.contactRolesDisplay
            && claimContact.contactRolesDisplay.length > 0
        ) {
            roles = claimContact.contactRolesDisplay;
        }
        return _.join(roles, ', ');
    };

    getPolicyTable = (claimDetailsData) => {
        const claimContactsWithoutAgent = _.filter(
            claimDetailsData.claimContacts,
            (claimContact) => {
                return claimContact.contactRoles.indexOf('agent') === -1;
            }
        ).map((claimContact) => {
            const roleInvolvement = this.getRoleInvolvement(claimContact);
            return {
                partyname: claimContact.contactDTO.displayName,
                involvement: roleInvolvement,
                primaryaddress: claimContact.contactDTO.primaryAddress,
                contactdetails: claimContact.contactDTO
            };
        });
        return claimContactsWithoutAgent;
    };

    getVehicleDetailsTable = (vehicles) => {
        return vehicles.map((vehicle) => {
            return {
                make: vehicle.make,
                model: vehicle.model,
                year: vehicle.year,
                license: vehicle.license,
                coverages: vehicle.coverages
            };
        });
    };

    getFormatCurrency = (deductible) => {
        let formattedAmount = '';
        if (deductible) {
            const { amount, currency } = deductible;
            if (amount && currency) {
                const locale = this.localeService.getPreferredLocale();
                formattedAmount = amount.toLocaleString(locale, {
                    style: 'currency',
                    currency,
                    currencyDisplay: 'code',
                });
            }
        }
        return formattedAmount;
    };

    getLimitType = (coverage, translator) => {
        if (coverage.incidentLimit && coverage.exposureLimit) {
            return (
                <div>
                    <p>{translator(messages.perAccident)}</p>
                    <p>{translator(messages.perPerson)}</p>
                </div>
            );
        }
        if (coverage.incidentLimit) {
            return translator(messages.perAccident);
        }
        if (coverage.exposureLimit) {
            return translator(messages.perPerson);
        }
        return '-';
    };

    handleError = (title, message) => {
        const { modalContext } = this.props;
        return modalContext.showAlert({
            title: title,
            message: message,
            status: 'error',
            icon: 'mi-error-outline',
            confirmButtonText: commonMessages.ok
        }).catch(_.noop);
    };

    getLimitValue = (coverage) => {
        if (coverage.incidentLimit && coverage.exposureLimit) {
            return (
                <div>
                    <p>{this.getFormatCurrency(coverage.incidentLimit)}</p>
                    <p>{this.getFormatCurrency(coverage.exposureLimit)}</p>
                </div>
            );
        }
        if (coverage.incidentLimit) {
            return this.getFormatCurrency(coverage.incidentLimit);
        }
        if (coverage.exposureLimit) {
            return this.getFormatCurrency(coverage.exposureLimit);
        }
        return '-';
    };

    getVehicleCoverageTable = (coverages, translator) => {
        return coverages.map((coverage) => {
            const limitValue = this.getLimitValue(coverage);
            const limitType = this.getLimitType(coverage, translator);
            const deductibleAmount = coverage.deductible
                ? this.getFormatCurrency(coverage.deductible)
                : '';
            return {
                type: coverage.name,
                deductible: deductibleAmount,
                limittype: limitType,
                limitvalue: limitValue
            };
        });
    };

    getBackToPreviousState = () => {
        const { history } = this.props;
        const { redirectPath } = this.state;
        const redirectpath = {
            pathname: redirectPath,
            state: 'claims'
        };
        history.push(redirectpath);
    };

    getClaimNumber = (claimDetailsData, translator) => {
        return translator(
            messages.claimDetailNumber,
            { num: claimDetailsData.claimNumber }
        );
    };

    getPolicyNumber = (claimDetailsData) => {
        let redirectPath = `/claims/policy/${claimDetailsData.claimNumber}`;

        if (['ProducerEngage', 'ServiceRepEngage'].includes(appConfig.applicationDetails?.applicationName)) {
            redirectPath = `/policies/${claimDetailsData.policy.policyNumber}/summary`;
        }

        return (
            <Link to={redirectPath} className={claimDetailsStyles.gwClaimDetailsLink}>
                {claimDetailsData.policy.policyNumber}
            </Link>
        );
    };

    getAccountNumber = (claimDetailsData) => {
        const redirectPath = `/accounts/${claimDetailsData.policy.accountNumber}/summary`;
        if (appConfig.persona === 'policyholder' || ['ProducerEngage for ClaimCenter'].includes(appConfig.applicationDetails?.applicationName)) {
            return claimDetailsData.policy.accountNumber;
        }
        return (
            <Link to={redirectPath} className={claimDetailsStyles.gwClaimDetailsLink}>
                {claimDetailsData.policy.accountNumber}
            </Link>
        );
    };

    getLossDate = (claimDetailsData, intl) => {
        return intl.formatDate(new Date(claimDetailsData.lossDate), { year: 'numeric', month: 'short', day: 'numeric' });
    };

    getReportedByName = (claimDetailsData, translator) => {
        if (claimDetailsData.claimReporter.reportedBy.displayName) {
            return claimDetailsData.claimReporter.reportedBy.displayName;
        }
        return translator(messages.unknown);
    };

    getPrimaryContactName = (claimDetailsData) => {
        if (claimDetailsData.mainContact && claimDetailsData.mainContact.displayName) {
            return claimDetailsData.mainContact.displayName;
        }
        return '';
    };

    getAdjusterName = (claimDetailsData, translator) => {
        if (claimDetailsData.adjuster.displayName) {
            return claimDetailsData.adjuster.displayName;
        }
        return translator(messages.unknown);
    };

    toggleVehicleDetailsContainer = (claimDetailsData) => {
        if (
            claimDetailsData.claim.policy.policyType === 'PersonalAuto'
            && claimDetailsData.vehicles.length > 0
        ) {
            return true;
        }
        return false;
    };

    // eslint-disable-next-line react/no-unused-class-component-methods
    getCoverageForSelectedVehicle = (claimDetailsData, translator) => {
        const { coverage } = this.state;
        const selectedVehicle = this.getInvolvedVehicles(claimDetailsData);
        if (!_.isEmpty(selectedVehicle)) {
            return translator(
                messages.coveragesFor,
                {
                    vehicleMake: selectedVehicle[coverage].make,
                    vehicleModel: selectedVehicle[coverage].model
                }
            );
        }
        return '';
    };

    getLossLocation = (claimDetailsData) => {
        let location = '';
        if (claimDetailsData.lossLocation && claimDetailsData.lossLocation.displayName) {
            location = claimDetailsData.lossLocation.displayName;
            location.replace('\n', ', ');
        }
        return location;
    };

    getLossLocationVisibility = (claimDetailsData) => {
        if (claimDetailsData.lossLocation && claimDetailsData.lossLocation.displayName) {
            return true;
        }
        return false;
    };

    getEmployerNotifiedVisibility = (claimDetailsData) => {
        if (claimDetailsData.lossType === 'WC') {
            return true;
        }
        return false;
    };

    getEmployerNotifiedDate = (claimDetailsData, intl) => {
        let employernotifiedDate;
        if ((claimDetailsData.lossType === 'WC') && (claimDetailsData.lobs.workersComp.dateReportedToEmployer)) {
            employernotifiedDate = intl.formatDate(
                new Date(claimDetailsData.lobs.workersComp.dateReportedToEmployer),
                { year: 'numeric', month: 'short', day: 'numeric' }
            );
        }
        return employernotifiedDate;
    };

    getHospitalDaysVisibility = (claimDetailsData) => {
        if (claimDetailsData.hospitalDays) {
            return true;
        }
        return false;
    };

    getInvolvedVehicles = (claimDetails) => {
        let involvedVehicles = [];
        const pa = claimDetails.lobs.personalAuto;
        const papolicy = claimDetails.policy.lobs.personalAuto;
        if (!pa) {
            return [];
        }
        if (
            pa.vehicleIncidents === null
            || pa.vehicleIncidents.length < 1
            || claimDetails.policy === null
            || papolicy.vehicles === null
        ) {
            return involvedVehicles;
        }

        const involvedVINarray = pa.vehicleIncidents.map((item) => {
            return item.vehicle.vIN;
        });
        involvedVehicles = _.filter(papolicy.vehicles, (vehicle) => {
            return involvedVINarray.indexOf(vehicle.vIN) !== -1;
        }).map((vehicle) => {
            return vehicle;
        });
        return involvedVehicles;
    };

    // eslint-disable-next-line react/no-unused-class-component-methods
    getSelectedVehicleCoverages = (claimDetails) => {
        const { coverage } = this.state;
        const selectedVehicle = this.getInvolvedVehicles(claimDetails);
        if (!_.isEmpty(selectedVehicle)) {
            return selectedVehicle[coverage].coverages;
        }
        return '';
    };

    showModal = () => {
        const translator = this.context;
        const { viewModelService, modalContext } = this.props;
        const componentProps = {
            title: translator(messages.adjusterAddnoteButton),
            actionBtnLabel: translator(messages.createNoteSave),
            cancelBtnLabel: translator(messages.createNoteCancel),
            viewModelService: viewModelService
        };

        return modalContext.showModal(
            <CreateNotePopUp {...componentProps} />
        );
    };

    saveNoteData = (updatedNoteVMObj) => {
        const {
            match: {
                params: { claimNumber }
            },
            authHeader,
            ClaimService
        } = this.props;
        const { claimDetailsData } = this.state;
        ClaimService.createClaimNote(
            claimNumber,
            updatedNoteVMObj.value,
            authHeader
        ).then((response) => {
            const newNote = this.getFormattedNotesArray([response]);
            claimDetailsData.notes = [...claimDetailsData.notes, ...newNote];
            return this.setState({
                selectedTab: 'notesTab',
                claimDetailsData: claimDetailsData
            });
        }).catch(() => {
            this.handleError(messages.createNoteErrorTitle, messages.createNoteErrorMessage);
        });
    };

    addNote = () => {
        this.showModal().then((updatedNoteVMObj) => {
            this.saveNoteData(updatedNoteVMObj);
        }).catch(_.noop);
    };

    getEmptyMessageVisibility = (notes) => {
        if (notes.length === 0) {
            return true;
        }
        return false;
    };

    getNotesTableVisibility = (notes) => {
        if (notes.length > 0) {
            return true;
        }
        return false;
    };

    getDataCell = (item, index, { path: property }) => {
        const translator = this.context;
        let toolTipMessage = '';
        switch (property) {
            case 'documentName':
                toolTipMessage = translator(messages.documentsName);
                break;
            case 'documentDate':
                toolTipMessage = translator(messages.documentsDateModified);
                break;
            default:
                toolTipMessage = '';
        }
        return (
            <span title={toolTipMessage}>
                {item[property]}
            </span>
        );
    };

    getViewandDownload = (item) => {
        const translator = this.context;
        const { ClaimDownloadService } = this.props;
        const { documentviewdownload: { workingPublicID, sessionID } } = item;
        const claimDocumentLink = ClaimDownloadService
            .getClaimDocument(workingPublicID, sessionID);
        return (
            <Link
                href={claimDocumentLink}
                target="_blank"
                key={item.documentviewdownload}
                className={claimDetailsStyles.documentIcon}
                title={translator(messages.documentsViewandDownload)}
            >
                <Icon icon="mi-insert_drive_file" />
            </Link>
        );
    };

    getDocumentRemove = (item) => {
        const translator = this.context;
        const { authHeader, ClaimService, modalContext } = this.props;
        const { claimDetailsData } = this.state;
        const { Authorization } = authHeader;
        const { documentremove } = item;
        const {
            claimNumber, publicID, name, canDelete
        } = documentremove;
        this.setState({ documentAlert: '' });
        if (!canDelete) {
            const alertMsg = translator(messages.unableToDeleteClaims, { documentName: name });
            this.setState({ documentAlert: alertMsg });
            return;
        }
        modalContext.showConfirm({
            title: messages.confirmationToRemoveTitle,
            message: translator(messages.confirmationToRemoveMessage, { documentname: name }),
            status: 'warning',
            icon: 'mi-error-outline',
            confirmButtonText: messages.removeConfirmationYes,
            cancelButtonText: messages.removeConfirmationNo
        }).then(async (results) => {
            if (results === 'cancel' || results === 'close') {
                return _.noop();
            }
            try {
                const isDeleteItem = await ClaimService.claimsRemoveDocument(
                    [claimNumber, publicID],
                    { Authorization: Authorization }
                );
                if (isDeleteItem === false) {
                    return this.handleError(
                        messages.deletionFailedTitle,
                        messages.deletionFailedMessage
                    );
                }
                claimDetailsData.claim.documents = _.filter(
                    claimDetailsData.claim.documents,
                    (doc) => {
                        return doc.publicID !== publicID;
                    }
                );
                return this.setState({
                    claimDetailsData: claimDetailsData
                });
            } catch (e) {
                return this.handleError(
                    messages.removeServiceFailedTitle,
                    messages.removeServiceFailedMessage
                );
            }
        }, _.noop);
    };

    getRemoveIcon = (item) => {
        const translator = this.context;
        return (
            <Icon
                icon="mi-delete"
                onClick={() => this.getDocumentRemove(item)}
                className={claimDetailsStyles.documentIcon}
                title={translator(messages.documentsRemove)}
            />
        );
    };

    formatAMPM = (date) => {
        let hours = date.getHours() % 12;
        let minutes = date.getMinutes();
        const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
        hours = hours || 12;
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        const strTime = `${hours}:${minutes} ${ampm}`;
        return strTime;
    };

    getDocumentsArray = (documents, intl) => {
        return documents.map((doc) => {
            const docDate = intl.formatDate(new Date(doc.dateModified), { year: 'numeric', month: 'short', day: 'numeric' });
            return {
                documentName: doc.name,
                documentDate: `${docDate} ${this.formatAMPM(new Date(doc.dateModified))}`,
                documentviewdownload: doc,
                documentremove: doc
            };
        });
    };

    getNotesArray = (notes, intl) => {
        return notes.map((noteData) => {
            const noteDate = intl.formatDate(new Date(noteData.date), { year: 'numeric', month: 'short', day: 'numeric' });
            return {
                author: noteData.author,
                date: `${noteDate} ${this.formatAMPM(new Date(noteData.date))}`,
                subject: noteData.subject,
                note: noteData.note
            };
        });
    };

    showServiceVendorAddress = (serviceData) => {
        const contactDTO = serviceData.vendor || serviceData;
        const vendor = { contactDTO };
        return <VendorLinkComponent id="vendorCell" vendors={[vendor]} />;
    };

    getVendorNameLink = (item) => {
        return <VendorLinkComponent id="vendorCell" vendors={[item]} />;
    };

    expanderContent = (data) => {
        const translator = this.context;
        const coverageArray = this.getVehicleCoverageTable(data.coverages, translator);
        const coveragesForVehicleHeader = translator(
            messages.coveragesFor,
            {
                vehicleMake: data.make,
                vehicleModel: data.model
            }
        );
        return (
            <>
                <h4>{coveragesForVehicleHeader}</h4>
                <DataTable
                    data={coverageArray}
                    id="vehicleCoverageTableId"
                    title={coveragesForVehicleHeader}
                    titleId="vehicleCoverageTitleId"
                    titlePosition="left"
                    expandable={false}
                    showSearch={false}
                    showPagination={false}
                >
                    <DisplayColumn
                        cell={(row) => row.type}
                        columnProportion={1}
                        header={translator(messages.type)}
                        id="typeHeader"
                        textAlign="left"
                        onSort={DatatableUtil.sortString}
                    />
                    <DisplayColumn
                        cell={(row) => row.deductible}
                        columnProportion={1}
                        header={translator(messages.deductible)}
                        id="deductibleHeader"
                        textAlign="left"
                        onSort={DatatableUtil.sortString}
                    />
                    <DisplayColumn
                        cell={(row) => row.limittype}
                        columnProportion={1}
                        header={translator(messages.limittype)}
                        id="limitTypeHead"
                        textAlign="left"
                        onSort={DatatableUtil.sortString}
                    />
                    <DisplayColumn
                        cell={(row) => row.limitvalue}
                        columnProportion={1}
                        header={translator(messages.limitvalue)}
                        id="limitValueHead"
                        textAlign="left"
                        onSort={DatatableUtil.sortNumber}
                    />
                </DataTable>
            </>
        );
    };

    formatPaymentDate = (claimPaymentDate, intl) => {
        return claimPaymentDate !== undefined ? intl.formatDate(new Date(claimPaymentDate), { year: 'numeric', month: 'short', day: 'numeric' }) : ' ';
    };

    // eslint-disable-next-line react/no-unused-class-component-methods
    getPaymentGrossAmount = (paymentGrossAmount) => {
        let grossAmount;
        const { amount, currency } = paymentGrossAmount;
        const { intl } = this.props;
        if (amount && currency) {
            grossAmount = intl.formatNumber(
                amount,
                {
                    style: 'currency',
                    currency: currency,
                    currencyDisplay: 'code'
                }
            );
        }
        return `${grossAmount}`;
    };

    getPaymentCheckNumber = (paymentCheckNumber) => {
        return paymentCheckNumber !== undefined ? paymentCheckNumber : ' ';
    };

    generatePaymentDetailsOverrides = (intl) => {
        const { claimDetailsData } = this.state;
        const translator = this.context;

        const paymentChecksPath = 'claimPayments';
        const paymentChecks = _.get(claimDetailsData, paymentChecksPath, []);
        const overrides = paymentChecks.map((paymentCheck, index) => {
            return {
                [`paymentScheduledDate${index}`]: {
                    value: this.formatPaymentDate(paymentCheck.scheduledDate, intl)
                },
                [`paymentIssueDate${index}`]: {
                    value: this.formatPaymentDate(paymentCheck.issueDate, intl)
                },
                [`paymentStatus${index}`]: {
                    value: translator({
                        id: `typekey.TransactionStatus.${paymentCheck.status}`,
                        defaultMessage: paymentCheck.status
                    })
                },
                [`paymentCheckNumber${index}`]: {
                    value: this.getPaymentCheckNumber(paymentCheck.checkNumber)
                },
                [`paymentPayee${index}`]: {
                    value: paymentCheck.payee.join(', ')
                }
            };
        });

        return Object.assign({}, ...overrides);
    };

    checkPaymentsHasDetails = () => {
        const { claimDetailsData } = this.state;
        return claimDetailsData.claim.checks && claimDetailsData.claim.checks.length > 0;
    };

    mapServiceStatus = (status) => {
        const translator = this.context;
        switch (status) {
            case 'workcomplete':
                return translator(messages.statusCompleted);
            case 'specialistwaiting':
            case 'requested':
            case 'inprogress':
            case 'draft':
                return translator(messages.statusInProgress);
            case 'expired':
            case 'declined':
            case 'canceled':
                return translator(messages.statusOffTrack);
            default:
                return translator(messages.statusUnknown);
        }
    };

    changeStatusIcon = (status) => {
        switch (status) {
            case 'workcomplete':
                return 'check';
            case 'specialistwaiting':
            case 'requested':
            case 'inprogress':
            case 'draft':
                return 'refresh';
            case 'expired':
            case 'declined':
            case 'canceled':
                return 'times';
            default:
                return ' ';
        }
    };

    showServiceCompletionDate = (serviceRequest) => {
        const translator = this.context;
        const checkServiceStatus = false;
        const serviceStatus = this.mapServiceStatus(serviceRequest.status);
        if (
            serviceStatus === translator(messages.statusInProgress)
            && serviceRequest.expectedServiceCompletionDate
        ) {
            return !checkServiceStatus;
        }
        return checkServiceStatus;
    };

    getServicePrimaryContact = (serviceRequest) => {
        const serviceContactRoles = `(${serviceRequest.customerClaimContact.contactRolesDisplay.join(
            ', '
        )})`;
        return (
            <>
                <ContactLinkComponent
                    contactDetails={serviceRequest.customerClaimContact.contactDTO}
                />
                <span>{serviceContactRoles}</span>
            </>
        );
    };

    generateServiceDetailsOverrides = (intl) => {
        const { claimDetailsData } = this.state;
        const serviceRequestsPath = 'claimServices';
        const serviceRequests = _.get(claimDetailsData, serviceRequestsPath, []);
        const overrides = serviceRequests.map((serviceRequest, index) => {
            return {
                [`serviceCompletionDate${index}`]: {
                    value: intl.formatDate(
                        new Date(serviceRequest.expectedServiceCompletionDate),
                        { year: 'numeric', month: 'short', day: 'numeric' }
                    ),
                    visible: this.showServiceCompletionDate(serviceRequest),
                },
                [`serviceStatusIcon${index}`]: {
                    icon: this.changeStatusIcon(serviceRequest.status)
                },
                [`serviceVendorLink${index}`]: {
                    value: this.showServiceVendorAddress(serviceRequest),
                },
                [`serviceStatus${index}`]: {
                    content: this.mapServiceStatus(serviceRequest.status)
                },
                [`servicePrimaryContact${index}`]: {
                    value: this.getServicePrimaryContact(serviceRequest),
                    visible:
                        appConfig.persona === 'producer'
                        || _.get(claimDetailsData, 'claim.policy.policyType', '') === 'WorkersComp'
                },
                [`serviceItems${index}`]: {
                    value: serviceRequest.services.join(', ')
                }
            };
        });

        return Object.assign({}, ...overrides);
    };

    checkServiceRequestsHasDetails = () => {
        const { claimDetailsData } = this.state;
        return (claimDetailsData.claim.serviceRequests
            && claimDetailsData.claim.serviceRequests.length > 0);
    };

    canAccessFinancials = () => {
        return appConfig.persona === 'producer' || appConfig.persona === 'csr';
    };

    claimsDocUploadToken = async () => {
        const { ClaimService, authHeader } = this.props;
        try {
            const sessionID = await ClaimService.claimsDocUploadToken([], authHeader);
            this.setState({ uploadToken: sessionID });
        } catch (e) {
            this.handleError(
                commonMessages.errorUploadTitle,
                commonMessages.errorGenerateUploadToken
            );
        }
    };

    handleDocumentsUpload = async (file) => {
        const { authHeader, match, ClaimService } = this.props;
        const { claimDetailsData, uploadToken } = this.state;
        const documentMetaDataTemplate = {
            docUID: '001',
            documentType: 'fnol',
            securityType: 'unrestricted',
            status: 'approved',
            claimNumber: match.params.claimNumber,
            name: file.name,
            mimeType: file.type,
            sessionID: uploadToken
        };

        try {
            const documentsData = await ClaimService.uploadDocument(
                file,
                documentMetaDataTemplate,
                authHeader
            );
            claimDetailsData.claim.documents = [...claimDetailsData.claim.documents, documentsData];
            this.setState({
                claimDetailsData: claimDetailsData
            });
        } catch (e) {
            this.handleError(commonMessages.errorUploadTitle, commonMessages.uploadFailedMessage);
        }
    };

    render() {
        const { intl } = this.props;
        const {
            claimDetailsData,
            isClaimDetailsPageVisible,
            isLoading,
            documentAlert,
            selectedTab
        } = this.state;
        const translator = this.context;
        if (isLoading) {
            return <Loader loaded={!isLoading} />;
        }

        if (!claimDetailsData) {
            return null;
        }

        const policyArray = this.getPolicyTable(claimDetailsData.claim);
        let vehicleDetailsArray = [];
        let claimNotesArray = [];
        let documentsArray = [];
        if (claimDetailsData.claim.documents.length > 0) {
            documentsArray = this.getDocumentsArray(claimDetailsData.claim.documents, intl);
        }
        if (this.toggleVehicleDetailsContainer(claimDetailsData)) {
            vehicleDetailsArray = this.getVehicleDetailsTable(claimDetailsData.vehicles);
        }
        if (claimDetailsData.notes.length > 0) {
            claimNotesArray = this.getNotesArray(claimDetailsData.notes, intl);
        }
        const overrides = {
            partiesInvolvedTableGrid: {
                data: policyArray
            },
            vendorInvolvedTableGrid: {
                data: claimDetailsData.claimContactsVendors
            },
            vehicleDetailsDataTable: {
                data: vehicleDetailsArray,
                renderExpanderContent: this.expanderContent,
                visible: vehicleDetailsArray.length > 0
            },
            notesTableGrid: {
                data: claimNotesArray,
                visible: this.getNotesTableVisibility(claimDetailsData.notes)
            },
            emptyDocsmsg: {
                visible: _.isEmpty(documentsArray)
            },
            claimStatus: {
                value: translator({
                    id: `typekey.ClaimState.${claimDetailsData.claim.claimState}`,
                    defaultMessage: claimDetailsData.claim.claimState
                })
            },
            documentsTableGrid: {
                data: documentsArray,
                visible: documentsArray.length > 0
            },
            removeDocumentNotification: {
                message: documentAlert,
                visible: !_.isEmpty(documentAlert)
            },
            primaryContact: {
                visible: !!this.getPrimaryContactName(claimDetailsData.claim),
                value: this.getPrimaryContactName(claimDetailsData.claim)
            },
            emptyNotesMessage: {
                visible: this.getEmptyMessageVisibility(claimDetailsData.notes)
            },
            claimDetailNumber: {
                content: this.getClaimNumber(claimDetailsData.claim, translator)
            },
            policyNumber: {
                value: this.getPolicyNumber(claimDetailsData.claim)
            },
            accountNumber: {
                value: this.getAccountNumber(claimDetailsData.claim)
            },
            lossLocation: {
                visible: this.getLossLocationVisibility(claimDetailsData.claim),
                value: this.getLossLocation(claimDetailsData.claim)
            },
            employerNotified: {
                visible: this.getEmployerNotifiedVisibility(claimDetailsData.claim),
                value: this.getEmployerNotifiedDate(claimDetailsData.claim, intl)
            },
            daysInHospital: {
                visible: this.getHospitalDaysVisibility(claimDetailsData.claim)
            },
            dateOfLoss: {
                value: this.getLossDate(claimDetailsData.claim, intl)
            },
            adjusterName: {
                value: this.getAdjusterName(claimDetailsData.claim, translator)
            },
            vehicleDetailsContainer: {
                visible:
                    ['producer', 'csr', 'policyholder'].includes(appConfig.persona)
                    && this.toggleVehicleDetailsContainer(claimDetailsData)
            },
            noClaimPaymentsDetails: {
                visible: !this.checkPaymentsHasDetails()
            },
            claimPaymentsDetails: {
                visible: this.checkPaymentsHasDetails()
            },
            noClaimServicesDetails: {
                visible: !this.checkServiceRequestsHasDetails()
            },
            claimServicesDetails: {
                visible: this.checkServiceRequestsHasDetails()
            },
            claimDetailsPage: {
                visible: isClaimDetailsPageVisible
            },
            summaryRightDiv: {
                visible: this.canAccessFinancials()
            },
            partyTableContainer: {
                visible: ['producer', 'vendor', 'csr'].includes(appConfig.persona)
            },
            claimDetailsTabset: {
                defaultActiveTab: selectedTab
            },
            vendorTableContainer: {
                visible: appConfig.persona === 'policyholder'
            },
            ...this.generatePaymentDetailsOverrides(intl),
            ...this.generateServiceDetailsOverrides(intl)
        };
        const resolvers = {
            resolveCallbackMap: {
                getBackToPreviousState: this.getBackToPreviousState,
                getCell: this.getCell,
                getDataCell: this.getDataCell,
                getNotesColumnCell: this.getNotesColumnCell,
                getLimitType: this.getLimitType,
                getPartyNameLink: this.getPartyNameLink,
                getVendorNameLink: this.getVendorNameLink,
                addNote: this.addNote,
                getRemoveIcon: this.getRemoveIcon,
                getViewandDownload: this.getViewandDownload,
                handleDocumentsUpload: this.handleDocumentsUpload,
                sortString: DatatableUtil.sortString,
                sortNumber: DatatableUtil.sortNumber,
                onRowClick: (data) => {
                    _.set(data, 'expanded', !data.expanded);
                }
            },
            resolveClassNameMap: claimDetailsStyles
        };

        const readValue = (id, path) => {
            return readViewModelValue(metadata.pageContent, claimDetailsData, id, path, overrides);
        };

        const claimDetailsPage = (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimDetailsData}
                overrideProps={overrides}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        );

        return <div className={cx(claimDetailsStyles.claimDetails)}>{claimDetailsPage}</div>;
    }
}

export const ClaimDetailsComponent = withModalContext(ClaimDetails);
export default withRouter(withViewModelService(
    withIntl(withAuthenticationContext(withDependencies(['ClaimService', 'ClaimDownloadService'])(withModalContext(ClaimDetails))))
));
