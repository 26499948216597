import React, {
    useCallback, useEffect, useState
} from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslator } from '@jutro/locale';
import { MetadataContent } from '@jutro/uiconfig';
import { useAuthentication, AUTH_ERRORS } from 'gw-digital-auth-react';
import AuthMessageComponent from '../AuthMessageComponent/AuthMessageComponent';
import { isEmpty } from '../LoginUtil';

import messages from './ResetPasswordComponent.messages';
import metadata from './ResetPasswordComponent.metadata.json5';
import styles from '../CommonLogin.module.scss';

function ResetPasswordComponent(props) {
    const translator = useTranslator();
    const [formData, updateFormData] = useState('');
    const [resetCode, setResetCode] = useState();
    const [pageError, updatePageError] = useState();
    const [pageView, setPageView] = useState();
    const {
        verifyResetCode, changePassword, authHeader, login
    } = useAuthentication();
    const { location, history } = props;

    useEffect(() => {
        const parsedParams = queryString.parse(location.search);

        verifyResetCode(parsedParams.token).then(
            (data) => {
                setResetCode(data.res);
            },
            () => {
                setPageView(AUTH_ERRORS.invalidToken);
            }
        );
        // Stop rendering
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleNewPassword = useCallback(
        (evt) => {
            if (evt) {
                evt.preventDefault();
            }

            if (isEmpty(formData.newPassword) || isEmpty(formData.confirmPassword)) {
                updatePageError(AUTH_ERRORS.fieldEmpty);
                return Promise.resolve();
            }
            if (!_.isEqual(formData.newPassword, formData.confirmPassword)) {
                updatePageError(AUTH_ERRORS.fieldIncorrect);
                return Promise.resolve();
            }

            const newPasswordObject = {
                new_password: formData.newPassword,
                code: resetCode
            };

            return changePassword(newPasswordObject, authHeader)
                .then((data) => {
                    const credentials = {
                        username: data.username,
                        password: formData.newPassword
                    };

                    login(credentials).then(
                        () => {
                            history.push('/login-page');
                        },
                        () => {
                            setPageView(AUTH_ERRORS.autoLoginError);
                        }
                    );
                })
                .catch((err) => {
                    switch (err.error) {
                        case AUTH_ERRORS.invalidTokenOrEmail:
                            setPageView(AUTH_ERRORS.invalidTokenOrEmail);
                            break;
                        default:
                            setPageView(AUTH_ERRORS.invalidAttempt);
                            break;
                    }
                });
        },
        [authHeader, changePassword, formData, history, login, resetCode]
    );

    const readValue = useCallback((id, path) => _.get(formData, path), [formData]);
    const writeValue = useCallback(
        (value, path) => updateFormData({
            ...formData,
            [path]: value
        }),
        [formData]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveValue: readValue,
        resolveComponentMap: {
            authmessagecomponent: AuthMessageComponent
        }
    };

    const overrides = {
        '@field': {
            onValueChange: writeValue
        },
        errorMsg: {
            visible: !_.isUndefined(pageError),
            content: translator(messages[pageError])
        },
        newPasswordFieldsContainer: {
            visible: !pageView
        },
        passwordForm: {
            onSubmit: handleNewPassword
        },
        actionsWrapperSingleButton: {
            className: classNames(styles.actionsWrapperSingleButton, styles.coupleButtonStyle)
        },
        changePassword: {
            onTrigger: handleNewPassword
        },
        successfulContainer: {
            visible: pageView === AUTH_ERRORS.autoLoginError
        },
        invalidTokenError: {
            visible: pageView === AUTH_ERRORS.invalidToken
        },
        invalidTokenOrPasswordError: {
            visible: pageView === AUTH_ERRORS.invalidTokenOrEmail
        },
        invalidAttemptError: {
            visible: pageView === AUTH_ERRORS.invalidAttempt
        }
    };

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrides}
            {...resolvers}
        />
    );
}

ResetPasswordComponent.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default withRouter(ResetPasswordComponent);
