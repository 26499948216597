import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { WizardPageTemplate } from 'gw-portals-wizard-components-ui';
import { useValidation } from 'gw-portals-validation-react';
import { fnolCommonMessages } from 'gw-capability-fnol-common-react';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './WaterDamagePage.metadata.json5';

function FNOLWaterDamagePage(props) {
    const {
        wizardData: claimVM,
        updateWizardData,
        history: {
            location: { state = {} }
        }
    } = props;
    const { claimStatus } = state;
    const translator = useTranslator();

    const {
        onValidate,
        initialValidation,
        isComponentValid,
        registerInitialComponentValidation
    } = useValidation('FNOLHOWaterDamagePage');

    const initialiseVM = useCallback(() => {
        const damageTypes = {
            waterdamage: _.find(claimVM.lossCause.aspects.availableValues, {
                code: 'waterdamage'
            }),
            mold: _.find(claimVM.lossCause.aspects.availableValues, {
                code: 'mold'
            })
        };

        claimVM.lobs.homeowners.propertyWaterDamage.value = !_.isEmpty(
            claimVM.lobs.homeowners.propertyWaterDamage.value
        )
            ? claimVM.lobs.homeowners.propertyWaterDamage.value
            : {
                isRoofProtected: true,
                hasWaterBeenTurnedOff: true
            };
        if (
            _.isNil(
                _.get(claimVM.value, 'lobs.homeowners.propertyWaterDamage.hasWaterBeenTurnedOff')
            )
        ) {
            _.set(claimVM.value, 'lobs.homeowners.propertyWaterDamage.hasWaterBeenTurnedOff', '');
        }
        claimVM.lossCause.value = !_.isEmpty(claimVM.lossCause.value)
            ? claimVM.lossCause.value
            : damageTypes.waterdamage;

        const sourceTypes = {
            plumbing_appliances: _.find(
                claimVM.lobs.homeowners.propertyWaterDamage.waterSource.aspects.availableValues,
                {
                    code: 'plumbing_appliances'
                }
            ),
            roof: _.find(
                claimVM.lobs.homeowners.propertyWaterDamage.waterSource.aspects.availableValues,
                {
                    code: 'roof'
                }
            ),
            other: _.find(
                claimVM.lobs.homeowners.propertyWaterDamage.waterSource.aspects.availableValues,
                {
                    code: 'other'
                }
            )
        };

        claimVM.lobs.homeowners.propertyWaterDamage.waterSource.value = !_.isEmpty(
            claimVM.lobs.homeowners.propertyWaterDamage.waterSource.value
        )
            ? claimVM.lobs.homeowners.propertyWaterDamage.waterSource.value
            : sourceTypes.plumbing_appliances;
        return claimVM;
    }, [claimVM]);

    useEffect(
        () => {
            const nextclaimVM = initialiseVM(claimVM);
            updateWizardData(nextclaimVM);
        },
        // It should call once when page gets rendered
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const typeOfDamageChange = useCallback(
        (value) => {
            _.set(claimVM.value, 'lossCause', value);
            updateWizardData(claimVM);
        },
        [claimVM, updateWizardData]
    );

    const getSourceOfWater = useCallback(
        (value) => {
            _.set(claimVM.value, 'lobs.homeowners.propertyWaterDamage.waterSource', value);
            updateWizardData(claimVM);
        },
        [claimVM, updateWizardData]
    );

    const isClaimStatus = useCallback(() => {
        return _.get(claimVM.value, 'lossCause') === 'waterdamage' && !_.isEmpty(claimStatus);
    }, [claimStatus, claimVM]);

    useEffect(() => {
        registerInitialComponentValidation(isClaimStatus);
    }, [registerInitialComponentValidation, isClaimStatus]);

    const overrides = {
        '@field': {
            showOptional: true,
            phoneWide: {
                labelPosition: 'top'
            }
        },
        typeOfDamageId: {
            value: _.get(claimVM.value, 'lossCause'),
            onValueChange: typeOfDamageChange
        },
        sourceOfWaterID: {
            value: _.get(claimVM.value, 'lobs.homeowners.propertyWaterDamage.waterSource'),
            onValueChange: getSourceOfWater
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrides);
        },
        [claimVM, overrides]
    );

    return (
        <WizardPage
            cancelLabel={translator(fnolCommonMessages.fnolSaveandExit)}
            skipWhen={initialValidation}
            template={WizardPageTemplate}
            disableNext={!isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrides}
                resolveValue={readValue}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

FNOLWaterDamagePage.propTypes = wizardProps;
export default withRouter(FNOLWaterDamagePage);
