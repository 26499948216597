import React, { useState, useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { PaymentComponent } from 'gw-components-platform-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import styles from './PaymentChoice.module.scss';
import metadata from './PaymentChoice.metadata.json5';
import './PaymentChoice.messages';

function Payment(props) {
    const { submissionVM, onWizardFooterUpdate, onPaymentCallback } = props;
    const [payInFullFlag, setPayInFullFlag] = useState(false);
    const [isBindingPolicy, setIsBindingPolicy] = useState(false);

    const displayPayInFull = useCallback(() => {
        setPayInFullFlag(true);
        onWizardFooterUpdate(false);
    }, [setPayInFullFlag, onWizardFooterUpdate]);

    const hidePayInFull = useCallback(() => {
        setPayInFullFlag(false);
        onWizardFooterUpdate(true);
    }, [setPayInFullFlag, onWizardFooterUpdate]);

    const periodStartDate = useCallback(() => {
        return _.get(submissionVM, 'baseData.periodStartDate.value');
    }, [submissionVM]);

    const periodEndDate = useCallback(() => {
        return _.get(submissionVM, 'baseData.periodEndDate.value');
    }, [submissionVM]);

    const bindPolicy = useCallback(
        async (paymentVM, paymentMethod) => {
            setIsBindingPolicy(true);
            const paymentDetails = {
                paymentMethod: paymentMethod
            };
            if (paymentMethod === 'wire') {
                paymentDetails.bankAccountData = paymentVM.value;
            }
            if (paymentMethod === 'creditcard') {
                paymentDetails.creditCardData = paymentVM.value;
            }
            if (onPaymentCallback) {
                await onPaymentCallback(submissionVM.jobID.value, paymentDetails);
            }
            setIsBindingPolicy(false);
        },
        [onPaymentCallback, submissionVM]
    );

    const redistribute = useCallback(() => {
        bindPolicy({}, 'redistribute');
    }, [bindPolicy]);

    const overrideProps = {
        paymentComponent: {
            xCenter: 'bc',
            visible: payInFullFlag
        },
        paymentOptionContainer: {
            visible: !payInFullFlag
        },
        existingPlanButton: {
            disabled: isBindingPolicy
        },
        policyPeriodField: {
            value: {
                startDate: periodStartDate,
                endDate: periodEndDate
            }
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            paymentcomponent: PaymentComponent
        },
        resolveCallbackMap: {
            displayPayInFull: displayPayInFull,
            hidePayInFull: hidePayInFull,
            payInFull: bindPolicy,
            redistribute: redistribute
        }
    };
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}
Payment.propTypes = {
    submissionVM: PropTypes.shape({
        jobID: PropTypes.shape({
            value: PropTypes.string
        })
    }).isRequired,
    onPaymentCallback: PropTypes.func.isRequired,
    onWizardFooterUpdate: PropTypes.func.isRequired
};
export default Payment;
