import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslator } from '@jutro/locale';
import { MetadataContent } from '@jutro/uiconfig';
import { useAuthentication, AUTH_ERRORS } from 'gw-digital-auth-react';
import AuthMessageComponent from '../AuthMessageComponent/AuthMessageComponent';
import { isEmpty, checkEmailValidity } from '../LoginUtil';

import messages from './ForgotPasswordComponent.messages';
import metadata from './ForgotPasswordComponent.metadata.json5';
import styles from '../CommonLogin.module.scss';

function ForgotPasswordComponent(props) {
    const translator = useTranslator();
    const [email, updateEmail] = useState('');
    const [pageError, updatePageError] = useState();
    const [pageView, setPageView] = useState();
    const { forgotPassword, authHeader } = useAuthentication();
    const { onBackToSignIn } = props;

    const handleForgotPassword = useCallback(
        (evt) => {
            if (evt) {
                evt.preventDefault();
            }

            if (isEmpty(email) || !checkEmailValidity(email)) {
                updatePageError(AUTH_ERRORS.fieldIncorrect);
                return Promise.resolve();
            }

            return forgotPassword(email, authHeader)
                .then(() => {
                    setPageView('success');
                })
                .catch((err) => {
                    switch (err.error) {
                        case AUTH_ERRORS.emailNotFound:
                            setPageView(AUTH_ERRORS.emailNotFound);
                            break;
                        default:
                            setPageView(AUTH_ERRORS.invalidAttempt);
                            break;
                    }
                });
        },
        [authHeader, email, forgotPassword]
    );

    const readValue = useCallback(() => email, [email]);
    const writeValue = useCallback((value) => updateEmail(value), []);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveValue: readValue,
        resolveCallbackMap: {
            handleBackToSignIn: onBackToSignIn
        },
        resolveComponentMap: {
            authmessagecomponent: AuthMessageComponent
        }
    };

    const overrides = {
        '@field': {
            onValueChange: writeValue
        },
        errorMsg: {
            visible: !_.isUndefined(pageError),
            content: translator(messages[pageError])
        },
        emailFieldContainer: {
            visible: !pageView
        },
        emailForm: {
            onSubmit: handleForgotPassword
        },
        actionsWrapperSingleButton: {
            className: classNames(styles.actionsWrapperSingleButton, {
                [styles.coupleButtonStyle]: !_.isUndefined(onBackToSignIn),
                [styles.singleButtonStyle]: _.isUndefined(onBackToSignIn)
            })
        },
        sendButton: {
            onTrigger: handleForgotPassword
        },
        linkToSignIn: {
            visible: !_.isUndefined(onBackToSignIn)
        },
        invalidAttemptError: {
            visible: pageView === AUTH_ERRORS.invalidAttempt
        },
        invalidEmailError: {
            visible: pageView === AUTH_ERRORS.emailNotFound
        },
        successfulContainer: {
            visible: pageView === 'success'
        }
    };

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrides}
            {...resolvers}
        />
    );
}

ForgotPasswordComponent.propTypes = {
    onBackToSignIn: PropTypes.func
};

ForgotPasswordComponent.defaultProps = {
    onBackToSignIn: undefined
};

export default ForgotPasswordComponent;
