import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { CoverageDetailsComponent } from 'gw-capability-claim-react';
import metadata from './PAVehicleCoverages.metadata.json5';
import styles from './PAVehicleCoverages.module.scss';

import messages from './PAVehicleCoverages.messages';

function PAVehicleCoverages(props) {
    const { value: model } = props;
    const translator = useTranslator();
    const [openItem, setOpenItem] = useState(null);

    const getNameCell = useCallback(
        (item, _index, property) => {
            return <span title={translator(messages.coverage)}>{item[property.id]}</span>;
        },
        [translator]
    );
    const toggleShowHide = useCallback((index) => {
        if (openItem === index) {
            setOpenItem(null); // close the currently opened item
        } else {
            setOpenItem(index);
        }
    }, [openItem]);

    const overrides = useMemo(() => {
        const vehicleOverrides = _.get(model, 'vehicles', [])
            .reduce((partial, vehicle, index) => ({
                ...partial,
                [`showContentToggleLink${index}`]: {
                    onClick: () => toggleShowHide(index),
                    icon: openItem === index ? 'mi-arrow-drop-up' : 'mi-arrow-drop-down',
                    content: openItem === index
                        ? translator(messages.hideVehicleCoverages)
                        : translator(messages.showVehicleCoverages),
                    visible: !_.isEmpty(_.get(model, `vehicles[${index}].coverages`))
                },
                [`contentDetailToggle${index}`]: {

                },
                [`coverageDetailsContainer${index}`]: {
                    visible: openItem === index
                },
                [`vehicleCoverageDetails${index}`]: {
                    coverages: _.get(vehicle, 'coverages'),
                    coveragesHeader: translator(messages.coveragesFor, {
                        vehicleMake: _.get(vehicle, 'make'),
                        vehicleModel: _.get(vehicle, 'model')
                    })
                }
            }), []);
        return vehicleOverrides;
    }, [model, openItem, toggleShowHide, translator]);

    if (_.isEmpty(model.vehicles)) {
        return null;
    }

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            coveragedetailscomponent: CoverageDetailsComponent
        },
        resolveCallbackMap: {
            getNameCell
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={model}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

PAVehicleCoverages.propTypes = {
    value: PropTypes.shape({}).isRequired
};

export default PAVehicleCoverages;
