import { setComponentMapOverrides } from '@jutro/uiconfig';

import FNOLGeneralWizard from './FNOLGeneralWizard';

setComponentMapOverrides(
    {
        FNOLGeneralWizard: { component: 'FNOLGeneralWizard' }
    },
    {
        FNOLGeneralWizard
    }
);

export {
    FNOLGeneralWizard as default
};
