import React from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './AdjusterContent.metadata.json5';
import adjusterStyles from './AdjusterContent.module.scss';

function AdjusterContentPage(props) {
    const { handleEditNote, handleDeleteNote, claimVM } = props;

    const resolvers = {
        resolveCallbackMap: {
            handleEditNote,
            handleDeleteNote
        },
        resolveClassNameMap: adjusterStyles
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={claimVM}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

AdjusterContentPage.propTypes = {
    handleEditNote: PropTypes.func.isRequired,
    handleDeleteNote: PropTypes.func.isRequired,
    claimVM: PropTypes.shape({
        adjusterNote: PropTypes.shape({
            value: PropTypes.shape({})
        })
    }).isRequired
};
export default AdjusterContentPage;
