import { useState, useCallback } from 'react';

/**
 * Used to force re-render a page after setting data from the viewModelForm
 *
 * @function useDataRefresh
 *
 * @memberof module:gw-portals-viewmodel-react
 *
 * @returns {Object} Refreshed data
 */
export default function useDataRefresh() {
    const [toggle, flip] = useState(false);

    const refreshData = useCallback(() => {
        flip(!toggle);
    }, [toggle]);

    return { refreshData };
}
