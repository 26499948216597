import React, { useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { Chevron, Icon } from '@jutro/components';
import { useValidation } from 'gw-portals-validation-react';
import { Grid } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { WizardPageTemplate } from 'gw-portals-wizard-components-ui';
import { fnolCommonMessages } from 'gw-capability-fnol-common-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import styles from './DetailsPage.module.scss';
import './DetailsPage.messages';
import metadata from './DetailsPage.metadata.json5';

function DetailsPage(props) {
    const translator = useTranslator();
    const {
        wizardData: claimVM,
        updateWizardData,
        history
    } = props;
    const {
        onValidate, isComponentValid, initialValidation, registerInitialComponentValidation
    } = useValidation('DetailsPage');

    const updatePropertyIncidents = useCallback((incidents, locations) => {
        let newIncidentList = [];
        if (locations) {
            incidents.forEach((incident) => {
                if (locations.some((location) => location.number === incident.location)) {
                    newIncidentList = [...newIncidentList, incident];
                }
            });
            locations.forEach((location) => {
                if (!incidents.some((incident) => {
                    return location.number === incident.location;
                })) {
                    newIncidentList = [...newIncidentList, { location: location.number }];
                }
            });
        }
        _.set(claimVM, 'lobs.commercialProperty.fixedPropertyIncidents.value', newIncidentList);
        updateWizardData(claimVM);
    }, [claimVM, updateWizardData]);

    useEffect(() => {
        const propertyIncidentsVM = _.get(claimVM, 'lobs.commercialProperty.fixedPropertyIncidents.value');
        const cpPolicyExtension = _.get(claimVM, 'policy.lobs.commercialProperty.locations.value');
        updatePropertyIncidents(propertyIncidentsVM, cpPolicyExtension);
        // execure once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderLocationAccordionHeader = useCallback((location, isOpen) => {
        const buildingData = _.get(claimVM.value, 'policy.lobs.commercialProperty.propertyRiskUnits');
        const buildingFilteredData = buildingData.filter((data) => data.locationNumber
                === location.number);
        const noOfBuilding = buildingFilteredData ? buildingFilteredData.length : 0;
        return (
            <React.Fragment>
                <Chevron isOpen={isOpen} />
                <Grid
                    id="gridaccordion"
                    columns={['80fr', '20fr']}
                >
                    <div className={styles.cpAcccordionHeader}>
                        <Icon icon="mi_place" />
                        <span>{location.address.displayName}</span>
                    </div>
                    <div className={styles.cpAcccordionHeader}>
                        <span>{noOfBuilding}</span>
                        <Icon icon="mi-business" />
                    </div>
                </Grid>
            </React.Fragment>
        );
    }, [claimVM]);

    const getEstimateReceivedStatus = useCallback((index) => {
        const incidentPath = `lobs.commercialProperty.fixedPropertyIncidents.children[${index}].value`;
        const selectedIncident = _.get(claimVM, incidentPath);
        return !_.isEmpty(selectedIncident) && (selectedIncident.estimateReceived === 'Yes');
    }, [claimVM]);

    const generateLocationOverrides = useCallback(
        () => {
            const locationsPath = 'lobs.commercialProperty.fixedPropertyIncidents';
            const incident = _.get(claimVM, `${locationsPath}.value`);
            const locations = _.filter(
                _.get(claimVM, 'policy.lobs.commercialProperty.locations.value'),
                (location) => {
                    return incident.some((incidentobj) => {
                        return location.number === incidentobj.location;
                    });
                }
            );

            const overrides = locations.map((location, index) => {
                return {
                    [`cpClaimSectionId${index}`]: {
                        renderHeader: (isOpen) => renderLocationAccordionHeader(location, isOpen)
                    },
                    [`cpClaimEstimateRepairCost${index}`]: {
                        visible: getEstimateReceivedStatus(index)
                    },
                    [`cpClaimEstimatedRepairTime${index}`]: {
                        visible: getEstimateReceivedStatus(index)
                    }
                };
            });

            return Object.assign({}, ...overrides);
        },
        [claimVM, getEstimateReceivedStatus, renderLocationAccordionHeader]
    );

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            phoneWide: {
                labelPosition: 'top'
            }
        },
        ...generateLocationOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    const { claimStatus } = history.location.state;

    const validateClaimStatus = useCallback(() => {
        return !_.isEmpty(claimStatus) && _.get(claimVM.value, 'lossLocation')
            && claimStatus === translator({ id: 'typekey.ClaimState.draft', defaultMessage: 'Draft' });
    }, [claimVM, claimStatus, translator]);

    useEffect(() => {
        registerInitialComponentValidation(validateClaimStatus);
    }, [validateClaimStatus, registerInitialComponentValidation]);

    return (
        <WizardPage
            cancelLabel={translator(fnolCommonMessages.fnolSaveandExit)}
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
            template={WizardPageTemplate}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

DetailsPage.propTypes = wizardProps;
export default withRouter(DetailsPage);
