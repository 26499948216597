import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { MetadataContent } from '@jutro/uiconfig';
import PropTypes from 'prop-types';
import { Link, useModal } from '@jutro/components';
import styles from '../../Claims.module.scss';
import ClaimServicesVendorPopupMetaData from './VendorContactDetailsPopup.metadata.json5';
import metadata from './VendorLinkComponent.metadata.json5';

function VendorLinkComponent(props) {
    const {
        showAlert
    } = useModal();

    const { vendors } = props;

    const getServiceVendorPopupModalContent = useCallback((item) => {
        const resolvers = {
            resolveValue: (id, path) => _.get(item, path),
            resolveClassNameMap: styles
        };
        return (
            <MetadataContent
                uiProps={ClaimServicesVendorPopupMetaData.pageContent}
                overrideProps={{}}
                {...resolvers}
            />
        );
    }, []);

    const openVendorPopup = useCallback(
        (event, item) => {
            event.preventDefault();
            const displayName = _.get(item, 'contactDTO.displayName', '');
            const renderServiceVendorContent = getServiceVendorPopupModalContent(item.contactDTO);
            showAlert({
                title: displayName,
                message: renderServiceVendorContent,
                status: 'info',
                icon: 'mi-error-outline'
            }).catch(_.noop);
        },
        [getServiceVendorPopupModalContent]
    );
    const getLinkComponent = useCallback((item) => {
        return (
            <Link onClick={(event) => openVendorPopup(event, item)} href="/">
                {item.contactDTO.displayName}
            </Link>
        );
    }, [openVendorPopup]);

    const generateOverrides = useMemo(() => {
        const overrides = vendors.map((item, index) => {
            return {
                [`serviceVendorLink${index}`]: {
                    content: getLinkComponent(item)
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [vendors, getLinkComponent]);

    const overrides = {
        vendorsIterable: {
            data: vendors
        },
        ...generateOverrides
    };

    return <MetadataContent uiProps={metadata.componentContent} overrideProps={overrides} />;
}

VendorLinkComponent.propTypes = {
    // eslint-disable-next-line react/require-default-props
    vendors: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

VendorLinkComponent.defaultProps = {
    // eslint-disable-next-line react/default-props-match-prop-types
    vendors: []
};

export default VendorLinkComponent;
