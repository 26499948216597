import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import './PDFViewer.module.scss';

/*
* Custom component to display a PDF
*/
export default function PDFViewer(props) {
    const {isPaymentTnC} = props;
    const [numPages, setNumPages] = useState(null);

    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    });

    function onDocumentLoadSuccess({ numPage }) {
        setNumPages(numPage);
        setPageNumber(1);
    }

    return (
        <div className="App">
            <Document
                file={isPaymentTnC === true ? "/PaymentTnC.pdf" : "/Terms1.pdf"}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                {Array.apply(null, Array(numPages))
                    .map((x, i) => i + 1)
                    .map((page) => <Page pageNumber={page} />)}

            </Document>
        </div>
    );
}
