import React, {
    useEffect, useState, useContext, useCallback
} from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import _ from 'lodash';
import { Loader, useModal } from '@jutro/components';
import { Wizard } from 'gw-portals-wizard-react';
import { ViewModelServiceContext, withViewModelService } from 'gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/prefer-default-export
import { useAuthentication } from 'gw-digital-auth-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { ErrorBoundary } from 'gw-portals-error-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { PolicyChange, messages as commonMessages } from 'gw-capability-policychange-common-react';
import { messages as platformMessages } from 'gw-platform-translations';
import messages from './PAPolicyChange.messages';
import wizardConfig from './config/pa-policychange-wizard-config.json5';
import styles from './PAPolicyChange.module.scss';

const setPeriodStartDate = (date) => {
    const currentDate = new Date(date);
    const effectiveDate = {
        day: currentDate.getDate(),
        month: currentDate.getMonth(),
        year: currentDate.getFullYear()
    };
    return effectiveDate;
};

function PAPolicyChangeWizard(props) {
    const {
        showConfirm,
        showAlert
    } = useModal();

    const { steps, title } = wizardConfig;
    const [initialSubmission, setInitialSubmission] = useState(null);
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const viewModelService = useContext(ViewModelServiceContext);
    const { location, history } = props;
    const { authHeader } = useAuthentication();
    const { EndorsementService } = useDependencies('EndorsementService');

    useEffect(
        () => {
            if (!location.state) {
                history.push('/');
                return;
            }
            const {
                state: { policyNumber, policyDetails, requestType }
            } = location;
            if (!policyNumber) {
                return;
            }
            const errorModalBox = (errorMessage) => {
                showConfirm(errorMessage).then((results) => {
                    if (results === 'cancel' || results === 'close') {
                        return _.noop();
                    }
                    setIsLoading(false);
                    return history.push(`/contactAgent/${policyNumber}`);
                }, _.noop);
            };
            EndorsementService.getAvailablePolicy(policyNumber, authHeader).then((response) => {
                if (appConfig.persona === 'policyholder') {
                    const effectivePolicyDate = (requestType === 'policyRenew') ? policyDetails.effective : new Date();
                    const date = setPeriodStartDate(effectivePolicyDate);
                    EndorsementService.loadEndorsementWithEffectiveDate(
                        [response.policyNumber, date],
                        authHeader
                    ).then((responseData) => {
                        const submission = viewModelService.create(
                            new PolicyChange(responseData),
                            'pc',
                            'edge.capabilities.policychange.dto.PolicyChangeDataDTO'
                        );
                        setInitialSubmission(submission);
                        setIsLoading(false);
                    });
                } else {
                    EndorsementService.loadEndorsement(response.policyNumber, authHeader)
                        .then((responseData) => {
                            const submission = viewModelService.create(
                                new PolicyChange(responseData),
                                'pc',
                                'edge.capabilities.policychange.dto.PolicyChangeDataDTO'
                            );
                            setInitialSubmission(submission);
                            setIsLoading(false);
                        })
                        .catch(() => {
                            errorModalBox({
                                title: commonMessages.unableToLoadDraftPolicy,
                                message: commonMessages.somethingWentWrong,
                                status: 'warning',
                                icon: 'mi-error-outline'
                            });
                        })
                        .catch(() => {
                            errorModalBox({
                                title: commonMessages.unableToStartPolicy,
                                message: commonMessages.anErrorOccurred,
                                status: 'warning',
                                icon: 'mi-error-outline'
                            });
                        });
                }
            });
        },
        // Disabled so we don't rerun this function on every rerender
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleOnCancel = useCallback(({ wizardData, wizardSnapshot, param: nextPath = '' }) => {
        const { submissionVM } = wizardData;
        const status = _.get(submissionVM.value, 'status');
        const jobID = _.get(submissionVM.value, 'jobID');
        const policyNumber = _.get(submissionVM.value, 'policyNumber');
        if ((status === 'Bound' && !_.isNil(jobID)) || (nextPath.startsWith('/change') || nextPath.startsWith('/contactAgent')) || hasErrorOccurred) {
            return true;
        }
        return showConfirm({
            title: platformMessages.wantToCancel,
            message: platformMessages.infoWillBeSavedAsDraft,
            status: 'warning',
            icon: 'mi-error-outline'
        }).then((results) => {
            if (results === 'cancel' || results === 'close') {
                return _.noop();
            }
            setIsLoading(true);
            const isValid = submissionVM.aspects.subtreeValid && submissionVM.aspects.valid;
            const payload = isValid ? submissionVM.value : _.get(wizardSnapshot, 'submissionVM.value');
            payload.beforeSave();
            const quoteStatus = _.get(wizardSnapshot, 'baseData.periodStatus.value.code')
                || _.get(submissionVM.value, 'status');
            const isStatusQuoted = quoteStatus !== 'Quoted';
            let redirectLobPath = (!_.isEmpty(nextPath) && nextPath) || `/change/${jobID}/summary`;
            if (!jobID) {
                redirectLobPath = `/policies/${policyNumber}/summary`;
            }
            let exitPromise;
            if (appConfig.persona === 'policyholder') {
                redirectLobPath = (!_.isEmpty(nextPath) && nextPath) || `/account-policy-details/${policyNumber}`;
            }
            if (appConfig.persona === 'policyholder') {
                if (quoteStatus !== 'Bound') {
                    exitPromise = EndorsementService.withdrawEndorsement([jobID], authHeader);
                } else {
                    return history.push(redirectLobPath);
                }
            } else if (isStatusQuoted) {
                exitPromise = EndorsementService.saveEndorsement([payload], authHeader);
            } else {
                return history.push(redirectLobPath);
            }
            exitPromise.then(() => {
                history.push(redirectLobPath);
            }).catch(() => {
                showAlert({
                    title: commonMessages.unableToDraftPolicy,
                    message: commonMessages.anErrorOccurred,
                    status: 'warning',
                    icon: 'mi-error-outline'
                });
            }).finally(() => {
                setIsLoading(false);
            });
            return true;
        }, _.noop);
    }, [hasErrorOccurred, showConfirm, EndorsementService, authHeader, history, showAlert]);

    const handleError = useCallback(
        (error) => {
            const isQuotePage = _.includes(_.toLower(_.get(error, 'gwInfo.method')), 'quote');
            const jobIDFromWizard = _.get(error, 'gwInfo.params[0].jobID');
            const jobIDFromLocation = _.get(location, 'state.policyNumber');
            let redirectPath = `/contactAgent/${jobIDFromLocation}`;
            if (!_.isEmpty(jobIDFromWizard)) {
                redirectPath = `/change/${jobIDFromWizard}/summary`;
            }
            const state = {
                pathname: redirectPath,
                state: {
                    redirectPath: '/home',
                }
            };

            return showAlert({
                title: isQuotePage ? messages.saveQuoteError : messages.anErrorOccurredTitle,
                message: isQuotePage ? messages.saveQuoteErrorMessage : messages.anErrorOccurred,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: platformMessages.ok
            }).then(() => {
                setHasErrorOccurred(true);
                return <Redirect to={state} />;
            }, _.noop);
        },
        [location, showAlert]
    );

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    if (!initialSubmission) {
        return null;
    }

    return (
        <div className={styles.paPolicyChangeWizardContainer}>
            <ErrorBoundary onError={handleError}>
                <Wizard
                    initialSteps={steps}
                    wizardTitle={title}
                    initialData={{
                        submissionVM: initialSubmission,
                        selectedPages: {
                            address: false,
                            vehicles: false,
                            drivers: false,
                            coverages: false
                        }
                    }}
                    onCancel={handleOnCancel}
                    onPreviousModalProps={{
                        title: platformMessages.wantToJump,
                        message: platformMessages.wantToJumpMessage,
                        status: 'warning',
                        icon: 'mi-error-outline',
                        confirmButtonText: platformMessages.yes,
                        cancelButtonText: platformMessages.no
                    }}
                    extractVMObject={(data) => _.get(data, 'submissionVM')}
                />
            </ErrorBoundary>
        </div>
    );
}

PAPolicyChangeWizard.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            address: PropTypes.shape({}),
            policyNumber: PropTypes.string,
            requestType: PropTypes.string,
            policyDetails: PropTypes.shape({
                effective: PropTypes.instanceOf(Date)
            })
        })
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired
};

export default withRouter(withViewModelService(PAPolicyChangeWizard));
