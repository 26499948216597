import React, {
    useState, useContext, useCallback, useEffect, useMemo
} from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import {
    Button,
    InputField,
    IconButton,
    DropdownSelectField,
    IntlPhoneNumberField,
    useModal
} from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { WizardPageTemplate } from 'gw-portals-wizard-components-ui';
import { useDependencies } from 'gw-portals-dependency-react';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { claimsMessages } from 'gw-capability-claim-react';
import { messages as commonMessages } from 'gw-platform-translations';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './AdditionalInformationPage.metadata.json5';
import styles from './AdditionalInformationPage.module.scss';
import messages from '../../FNOL.messages';
import Claim from '../../models/Claim';

const ROLES_TO_FILTER = ['witness', 'other'];

const getPhoneFieldBasedOnPrimaryPhoneType = (relatedContact) => {
    let primaryPhoneType;

    switch (relatedContact.contact.primaryPhoneType) {
        case 'home':
            primaryPhoneType = 'homeNumber';
            break;
        case 'work':
            primaryPhoneType = 'workNumber';
            break;
        case 'mobile':
            primaryPhoneType = 'cellNumber';
            break;
        default:
            primaryPhoneType = 'homeNumber';
    }

    return primaryPhoneType;
};

function FNOLAdditionalInformationPage(props) {
    const {
        showAlert,
        showConfirm
    } = useModal();

    const {
        wizardData: claimVM, updateWizardData, authHeader
    } = props;
    const { ClaimDownloadService } = useDependencies('ClaimDownloadService');
    const { ClaimService } = useDependencies('ClaimService');
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const [claimObj, updateClaimObj] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const { onValidate, isComponentValid, registerComponentValidation } = useValidation(
        'FNOLAdditionalInformationPage'
    );

    const showInjuredColumn = useMemo(
        () => !['InlandMarine', 'GeneralLiability', 'BusinessOwners'].includes(_.get(claimVM, 'policy.policyType.value')),
        // Policy type won't change on re-renders
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const writeValue = useCallback(
        (value, path) => {
            _.set(claimVM, path, value);
            updateWizardData(claimVM);
            _.set(claimObj, 'relatedContacts', claimVM.relatedContacts.value);
        },
        [claimObj, claimVM, updateWizardData]
    );

    const handleError = useCallback((title, message) => showAlert({
        title,
        message,
        status: 'error',
        icon: 'mi-error-outline',
        confirmButtonText: commonMessages.ok
    }).catch(_.noop), [showAlert]);

    useEffect(() => {
        updateClaimObj(new Claim(claimVM.value));
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFirstLastNameData = useCallback(
        (item, rowId, property) => {
            const { id, path } = property;
            const claimVMPath = `relatedContacts.value[${rowId}].${path}`;
            const setDisable = item.contact.publicID
                ? 'disabled'
                : '';

            return (
                <InputField
                    id={`${id}_${rowId}`}
                    className={styles.tableColumn}
                    path={claimVMPath}
                    onValueChange={writeValue}
                    value={_.get(claimVM, claimVMPath)}
                    disabled={setDisable}
                />
            );
        },
        [claimVM, writeValue]
    );

    const filteredRoleTypeList = useMemo(() => {
        const typeList = viewModelService.create(
            {},
            'cc',
            'edge.capabilities.claim.fnol.dto.FnolRelatedContactDTO'
        );
        const filteredList = typeList.role.aspects.availableValues
            .filter((typeCode) => ROLES_TO_FILTER.includes(typeCode.code))
            .map((typeCode) => ({
                code: typeCode.code,
                name: translator({
                    id: typeCode.name,
                    defaultMessage: typeCode.name
                })
            }));

        return filteredList;
    }, [translator, viewModelService]);

    const getInvolvementInjuredData = useCallback(
        (item, rowId, property) => {
            const { id, path } = property;
            let availableValues;
            const claimVMPath = `relatedContacts.value[${rowId}].${path}`;

            if (id === 'role') {
                availableValues = filteredRoleTypeList;
            } else {
                availableValues = [
                    {
                        code: 'true',
                        name: translator(messages.fnolYes)
                    },
                    {
                        code: 'false',
                        name: translator(messages.fnolNo)
                    }
                ];
            }

            return (
                <DropdownSelectField
                    id={`${id}_${rowId}`}
                    alwaysShowPlaceholder={false}
                    availableValues={availableValues}
                    path={claimVMPath}
                    onValueChange={writeValue}
                    value={_.get(claimVM, claimVMPath)}
                    className={styles.involvmentInjuredDropDown}
                />
            );
        },
        [claimVM, filteredRoleTypeList, translator, writeValue]
    );

    const getPhoneNumberData = useCallback(
        (item, rowId) => {
            const phoneField = getPhoneFieldBasedOnPrimaryPhoneType(item);
            const claimVMPath = `relatedContacts.value[${rowId}].contact.${phoneField}`;
            const setDisable = item.contact.publicID
                ? 'disabled'
                : '';

            return (
                <IntlPhoneNumberField
                    id={`${item.primaryPhoneID}_${rowId}`}
                    dataType="string"
                    onValueChange={writeValue}
                    path={claimVMPath}
                    disabled={setDisable}
                    value={_.get(claimVM, claimVMPath)}
                />
            );
        },
        [claimVM, writeValue]
    );

    const showDocumentDeleteIcon = useCallback((documentUploadItem) => {
        if (documentUploadItem.canDelete) {
            return true;
        }

        return false;
    }, []);

    const onDeleteDocumentIcon = useCallback(
        (e, item) => {
            e.preventDefault();

            const { claimNumber, publicID, name } = item;

            showConfirm({
                title: claimsMessages.confirmationToRemoveTitle,
                message: translator(messages.fnolRemoveUploadedDocument, { documentName: name }),
                status: 'warning',
                icon: 'mi-error-outline',
                confirmButtonText: claimsMessages.removeConfirmationYes,
                cancelButtonText: claimsMessages.removeConfirmationNo
            }).then(async (results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }

                setShowLoader(true);

                try {
                    const isDeleteItem = await ClaimService.claimsRemoveDocument(
                        [claimNumber, publicID],
                        authHeader
                    );

                    if (isDeleteItem === false) {
                        setShowLoader(false);

                        return handleError(
                            claimsMessages.deletionFailedTitle,
                            claimsMessages.deletionFailedMessage
                        );
                    }

                    const documentsArray = _.get(claimVM, 'documents.value');
                    const updatedDocArray = documentsArray.filter(
                        (documentObj) => documentObj.publicID !== publicID
                    );
                    const newClaimVM = _.clone(claimVM);

                    _.set(newClaimVM, 'documents.value', updatedDocArray);
                    updateWizardData(newClaimVM);
                    setShowLoader(false);

                    return true;
                } catch (documentDeletionError) {
                    setShowLoader(false);

                    return handleError(
                        claimsMessages.removeServiceFailedTitle,
                        claimsMessages.removeServiceFailedMessage
                    );
                }
            }, _.noop);
        },
        [translator, ClaimService, authHeader, claimVM, updateWizardData, handleError, showConfirm]
    );

    const handleDocumentDownload = useCallback(
        (item) => {
            if (_.isNil(item)) {
                return null;
            }

            const { workingPublicID, sessionID } = item;

            return ClaimDownloadService
                .getClaimDocument(workingPublicID, sessionID);
        },
        [ClaimDownloadService]
    );

    const generateMoreInfoDocumentsOverrides = useCallback(() => {
        const documentUploadPath = 'value.documents';
        const documentUploadItems = _.get(claimVM, documentUploadPath, []);
        const overrides = documentUploadItems.map((documentUploadItem, index) => ({
            [`documentNameLink${index}`]: {
                href: handleDocumentDownload(documentUploadItem),
                content: documentUploadItem.name
            },
            [`documentDeleteIcon${index}`]: {
                onClick: (e) => {
                    onDeleteDocumentIcon(e, documentUploadItem);
                },
                visible: showDocumentDeleteIcon(documentUploadItem)
            }
        }));

        return Object.assign({}, ...overrides);
    }, [
        claimVM,
        handleDocumentDownload,
        onDeleteDocumentIcon,
        showDocumentDeleteIcon
    ]);

    const getContactsRelatedPersons = useCallback((contactsItem) => {
        if (contactsItem.subtype === 'Person' && contactsItem.lastName) {
            return `${contactsItem.firstName} ${contactsItem.lastName}`;
        }

        return '';
    }, []);

    const generateMoreInfoAddPersonOverrides = useCallback(() => {
        const contactsPath = 'value.contacts';
        const contactsItems = _.get(claimVM, contactsPath, []);
        let availableRelatedContacts = [];

        if (!_.isEmpty(claimObj)) {
            availableRelatedContacts = claimObj.availableRelatedContacts().filter((contact) => contact.subtype === 'Person' && contact.firstName);
        }

        if (!contactsItems.length) {
            return {};
        }

        const overrides = contactsItems.map((contactItem, index) => ({
            [`contactItemsDropDown${index}`]: {
                content: getContactsRelatedPersons(contactItem),
                visible: _.includes(availableRelatedContacts, contactItem)
            }
        }));

        return Object.assign({}, ...overrides);
    }, [claimVM, claimObj, getContactsRelatedPersons]);

    const removeContactItem = useCallback(
        (e, rowId) => {
            e.preventDefault();

            const { relatedContacts } = claimVM;
            const relatedContactToDelete = relatedContacts.value.filter(
                (relatedContact, index) => index === rowId
            );

            claimObj.removeRelatedContact(relatedContactToDelete[0].contact);
            _.set(claimVM.value, 'relatedContacts', claimObj.relatedContacts);
            updateWizardData(claimVM);
        },
        [claimVM, claimObj, updateWizardData]
    );

    const removeContactRow = useCallback(
        (item, index) => (
            <IconButton
                icon="mi-delete"
                onClick={(e) => removeContactItem(e, index, item)}
            />
        ),
        [removeContactItem]
    );

    const generateNewWitnessRow = useCallback(
        (event) => {
            event.preventDefault();

            const { contacts } = claimVM;
            const getCurrentMenuItemID = event.currentTarget.id;

            if (getCurrentMenuItemID.indexOf('anotherPersonLink') === -1) {
                const getIndex = parseInt(getCurrentMenuItemID.match(/[0-9]/gi), 10);
                const selectedContact = contacts.value[getIndex];

                claimObj.addRelatedContact(selectedContact);
                _.set(claimVM.value, 'relatedContacts', claimObj.relatedContacts);
            } else {
                claimObj.addRelatedContact(null);
                _.set(claimVM.value, 'relatedContacts', claimObj.relatedContacts);
            }

            updateWizardData(claimVM);
        },
        [claimVM, updateWizardData, claimObj]
    );

    const generateAddPersonMenuDropDown = useCallback(
        (dropDownProps, toggleMenu) => {
            const { isOpen, ref } = dropDownProps;
            const onAddPersonClick = () => {
                toggleMenu(!isOpen);
            };

            return (
                <Button onClick={onAddPersonClick} icon="mi-expand_more" iconPosition="right" ref={ref}>
                    {translator(messages.fnolAddPerson)}
                </Button>
            );
        },
        [translator]
    );

    const claimsDocUploadToken = useCallback(async () => {
        try {
            const uploadTokenID = await ClaimService.claimsDocUploadToken([], authHeader);

            return uploadTokenID;
        } catch (e) {
            return handleError(
                commonMessages.errorUploadTitle,
                commonMessages.errorGenerateUploadToken
            );
        }
    }, [ClaimService, authHeader, handleError]);

    const onUploadDocument = useCallback(
        async (file) => {
            setShowLoader(true);

            const documentMetaDataTemplate = {
                DocUID: '001',
                DocumentType: 'fnol',
                SecurityType: 'unrestricted',
                Status: 'approved',
                Author: 'Policy Holder',
                claimNumber: claimVM.claimNumber.value,
                name: file.name,
                mimeType: file.type,
                sessionID: await claimsDocUploadToken()
            };

            try {
                const data = await ClaimService.uploadDocument(
                    file,
                    documentMetaDataTemplate,
                    authHeader
                );
                const documentsArray = _.get(claimVM, 'documents.value');

                documentsArray.push(data);

                const newClaimVM = _.clone(claimVM);

                _.set(newClaimVM, 'documents.value', documentsArray);
                updateWizardData(newClaimVM);
                setShowLoader(false);
            } catch (error) {
                setShowLoader(false);
                handleError(commonMessages.errorUploadTitle, commonMessages.uploadFailedMessage);
            }
        },
        [claimVM, claimsDocUploadToken, ClaimService, authHeader, updateWizardData, handleError]
    );

    const validateTableForm = useCallback(() => {
        if (
            !claimVM.relatedContacts.aspects.valid
            || !claimVM.relatedContacts.aspects.subtreeValid
        ) {
            return false;
        }

        return true;
    }, [claimVM]);

    const overrides = {
        addtionalInformationLoader: {
            loaded: !showLoader
        },
        uploadDocumentGrid: {
            visible: !showLoader
        },
        witnessPartyTable: {
            data: claimVM.relatedContacts.value,
            visible: !_.isEmpty(claimVM.relatedContacts.value)
        },
        injuredHeader: {
            visible: showInjuredColumn
        },
        ...generateMoreInfoDocumentsOverrides(),
        ...generateMoreInfoAddPersonOverrides()
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            generateWitnessPartyTableRow: generateNewWitnessRow,
            getFirstNameData: getFirstLastNameData,
            getLastNameData: getFirstLastNameData,
            getInvolvementData: getInvolvementInjuredData,
            getPhoneNumberData,
            getInjuredData: getInvolvementInjuredData,
            removeContactRow,
            onUploadDocument,
            renderTrigger: generateAddPersonMenuDropDown
        }
    };

    useEffect(() => {
        registerComponentValidation(validateTableForm);
    }, [registerComponentValidation, validateTableForm]);

    return (
        <WizardPage
            cancelLabel={translator(messages.fnolSaveandExit)}
            disableNext={!isComponentValid}
            template={WizardPageTemplate}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrides}
                onModelChange={updateWizardData}
                onValueChange={writeValue}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

FNOLAdditionalInformationPage.propTypes = wizardProps;
export default withRouter(withAuthenticationContext(FNOLAdditionalInformationPage));
