import React, {
    useEffect, useState, useContext, useCallback
} from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Wizard } from 'gw-portals-wizard-react';
import { ViewModelServiceContext, withViewModelService } from 'gw-portals-viewmodel-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { ErrorBoundary } from 'gw-portals-error-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { Loader, useModal } from '@jutro/components';
import { messages as commonMessages } from 'gw-platform-translations';
import Claim from './models/Claim';

import ClaimUtil from './utils/ClaimUtil';
import wizardConfig from './config/base-fnol-wizard-config.json5';
import messages from './FNOL.messages';
import styles from './BaseFNOLWizard.module.scss';

const ACTION_MSG = {
    cancelAction: {
        title: commonMessages.wantToCancel,
        message: messages.cancelModalMessage,
        status: 'warning',
        icon: 'mi-error-outline',
        confirmButtonText: commonMessages.yes,
        cancelButtonText: commonMessages.close
    },
    saveAndExitAction: {
        title: messages.saveAndExitModalTitle,
        message: messages.saveAndExitModalMessage,
        status: 'warning',
        icon: 'mi-error-outline',
        confirmButtonText: commonMessages.yes,
        cancelButtonText: commonMessages.close
    },
    previousAction: {
        title: commonMessages.wantToJump,
        message: commonMessages.wantToJumpMessage,
        status: 'warning',
        icon: 'mi-error-outline',
        confirmButtonText: commonMessages.yes,
        cancelButtonText: commonMessages.close
    }
};

function BaseFNOLWizard(props) {
    const {
        showAlert,
        showConfirm
    } = useModal();

    const { steps, title } = wizardConfig;
    const { location, history } = props;
    const { authHeader } = useAuthentication();
    const { FNOLService } = useDependencies('FNOLService');
    const [getRedirectPath, setRedirectPath] = useState();
    const [initialClaim, setInitialClaim] = useState(null);
    const viewModelService = useContext(ViewModelServiceContext);
    const [isLoading, setIsLoading] = useState(false);
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);

    useEffect(
        () => {
            if (!location.state) {
                return history.push('/');
            }
            const { redirectPath } = location.state;
            setRedirectPath(redirectPath);
            const claimVM = viewModelService.create(
                new Claim(),
                'cc',
                'edge.capabilities.claim.fnol.dto.FnolDTO'
            );

            return setInitialClaim(claimVM);
        },
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const saveDraftClaim = useCallback(
        (model, toRedirectpath) => {
            setIsLoading(true);
            FNOLService.saveFNOLDetails(model.value, authHeader)
                .then(() => {
                    return history.push({
                        pathname: toRedirectpath,
                        state: 'claims'
                    });
                })
                .catch(() => {
                    showAlert({
                        title: messages.fnolSaveDraftErrorTitle,
                        message: messages.fnolSaveDraftErrorMessage,
                        status: 'error',
                        icon: 'mi-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).catch(_.noop);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        },
        [FNOLService, authHeader, history, showAlert]
    );

    const handleOnCancel = useCallback(
        ({ wizardSnapshot, param: nextPath = '' }) => {
            if ((!_.isEmpty(nextPath) && nextPath.startsWith('/fnol')) || hasErrorOccurred) {
                return null;
            }
            let toRedirectpath = nextPath;
            if (_.isEmpty(nextPath)) {
                toRedirectpath = getRedirectPath;
            }
            const message = wizardSnapshot.claimNumber.value
                ? ACTION_MSG.saveAndExitAction
                : ACTION_MSG.cancelAction;
            return showConfirm(message).then((results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }
                setIsLoading(true);
                if (!wizardSnapshot.claimNumber.value) {
                    history.push({
                        pathname: toRedirectpath,
                        state: 'claims'
                    });
                    setIsLoading(false);
                    // returning undefined since arrow functions require return value
                    return undefined;
                }
                return saveDraftClaim(wizardSnapshot, toRedirectpath);
            }, _.noop);
        },
        [hasErrorOccurred, getRedirectPath, saveDraftClaim, history, showConfirm]
    );

    const handleError = useCallback(
        (error) => {
            const state = {
                pathname: ClaimUtil.getCancelPath(initialClaim.value),
                state: error
            };

            return showAlert({
                title: commonMessages.genericError,
                message: commonMessages.genericErrorMessage,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.ok
            }).then(() => {
                setHasErrorOccurred(true);
                return <Redirect to={state} />;
            }, _.noop);
        },
        [initialClaim, showAlert]
    );

    if (isLoading) {
        return <Loader loaded={!isLoading} text={messages.fnolSaveDraftClaim} />;
    }

    if (!initialClaim) {
        return null;
    }

    return (
        <div className={styles.FNOLWizardContainer}>
            <ErrorBoundary onError={handleError}>
                <Wizard
                    initialSteps={steps}
                    wizardTitle={title}
                    initialData={initialClaim}
                    skipCompletedSteps
                    onCancel={handleOnCancel}
                />
            </ErrorBoundary>
        </div>
    );
}
BaseFNOLWizard.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            redirectPath: PropTypes.string,
            claimData: PropTypes.shape({})
        })
    }).isRequired
};
export default withRouter(withViewModelService(BaseFNOLWizard));
