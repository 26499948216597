import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { useTranslator } from '@jutro/locale';
import { useValidation } from 'gw-portals-validation-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { WizardPageTemplate } from 'gw-portals-wizard-components-ui';
import { fnolCommonMessages } from 'gw-capability-fnol-common-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './FireDamagePage.metadata.json5';

function FNOLFireDamagePage(props) {
    const {
        wizardData: claimVM,
        updateWizardData,
        history: {
            location: { state = {} }
        }
    } = props;
    const { claimStatus } = state;
    const translator = useTranslator();

    const {
        onValidate,
        initialValidation,
        isComponentValid,
        registerInitialComponentValidation
    } = useValidation('FNOLHOFireDamagePage');

    const initialiseVM = useCallback(() => {
        claimVM.lobs.homeowners.propertyFireDamage.value = !_.isEmpty(
            claimVM.lobs.homeowners.propertyFireDamage.value
        )
            ? claimVM.lobs.homeowners.propertyFireDamage.value
            : {
                isHomeHabitable: true,
                isHomeSecure: true
            };
        if (_.isNil(_.get(claimVM.value, 'lobs.homeowners.propertyFireDamage.isHomeSecure'))) {
            _.set(claimVM.value, 'lobs.homeowners.propertyFireDamage.isHomeSecure', '');
        }
        if (_.isNil(_.get(claimVM.value, 'lobs.homeowners.propertyFireDamage.isHomeHabitable'))) {
            _.set(claimVM.value, 'lobs.homeowners.propertyFireDamage.isHomeHabitable', '');
        }
        return claimVM;
    }, [claimVM]);

    const isClaimStatus = useCallback(() => {
        return _.get(claimVM.value, 'lossCause') === 'fire' && !_.isEmpty(claimStatus);
    }, [claimStatus, claimVM]);

    useEffect(() => {
        registerInitialComponentValidation(isClaimStatus);
    }, [registerInitialComponentValidation, isClaimStatus]);

    useEffect(
        () => {
            const nextclaimVM = initialiseVM();
            updateWizardData(nextclaimVM);
        },
        // Call once when page gets rendered
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const overrides = {
        '@field': {
            showOptional: true,
            phoneWide: {
                labelPosition: 'top'
            }
        }
    };

    return (
        <WizardPage
            cancelLabel={translator(fnolCommonMessages.fnolSaveandExit)}
            skipWhen={initialValidation}
            template={WizardPageTemplate}
            disableNext={!isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrides}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

FNOLFireDamagePage.propTypes = wizardProps;
export default withRouter(FNOLFireDamagePage);
