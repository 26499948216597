import _ from 'lodash';

function getCancelPath(submission) {
    const accountNumber = _.get(submission, 'policy.accountNumber');
    const policyNumber = _.get(submission, 'policy.policyNumber');

    if (policyNumber) {
        return `/policies/${policyNumber}/claims`;
    }
    if (accountNumber) {
        return `/accounts/${accountNumber}/claims`;
    }
    return '/claims';
}

export default {
    getCancelPath
};
