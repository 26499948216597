import React from 'react';
import { FieldComponent } from '@jutro/components';

// eslint-disable-next-line import/prefer-default-export
export class CustomInputField extends FieldComponent {
    static propTypes = {
        ...FieldComponent.propTypes
    };

    static defaultProps = {
        ...FieldComponent.defaultProps
    };

    /**
     * Render control for this component.
     *
     * @param {object} breakpointProps - breakpoint-specific props
     *
     * @returns {React.ReactElement} JSX for the control
     */
    // eslint-disable-next-line class-methods-use-this
    renderControlReadOnly(breakpointProps) {
        const {
            children
        } = breakpointProps;

        const child = Array.isArray(children) ? children[0] : children;
        return (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <React.Fragment>
                {child}
            </React.Fragment>
        );
    }
}
