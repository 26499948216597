import React, {
    useCallback, useMemo, useEffect, useState
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { WizardPageTemplate } from 'gw-portals-wizard-components-ui';
import { useDependencies } from 'gw-portals-dependency-react';
import { withRouter } from 'react-router-dom';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import {
    IconButton,
    Tooltip,
    useModal
} from '@jutro/components';
import { useValidation } from 'gw-portals-validation-react';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { messages as commonMessages } from 'gw-platform-translations';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import Claim from '../../models/Claim';
import metadata from './TypeOfIncidentPage.metadata.json5';
import messages from '../../FNOL.messages';

function FNOLTypeOfIncidentPage(props) {
    const {
        showConfirm,
        showAlert
    } = useModal();

    const {
        wizardData: claimVM,
        updateWizardData,
        authHeader,
        history: {
            location: { state = {} }
        }
    } = props;
    const translator = useTranslator();
    const { FNOLService } = useDependencies('FNOLService');
    const { policyType } = _.get(claimVM, 'policy.value');
    const lobPolicyType = _.lowerFirst(policyType);
    const lobExtension = claimVM.lobs[lobPolicyType];
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    if (_.isEmpty(claimVM.generalLob)) {
        _.set(claimVM, 'generalLob', lobExtension);
    }
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerInitialComponentValidation
    } = useValidation('FNOLTypeOfIncidentPage');

    const setAvailableLossCauseValue = useMemo(() => {
        const lossDataArray = _.sortBy(claimVM.lossCause.aspects.availableValues.map((key) => {
            return {
                name: translator({
                    id: key.name,
                    defaultMessage: key.name
                }),
                code: key.code
            };
        }));
        return lossDataArray;
    }, [claimVM, translator]);

    const handleAddAnInjured = useCallback(() => {
        claimVM.generalLob.value.addInjuryIncident();
        updateWizardData(claimVM);
    }, [claimVM, updateWizardData]);

    const handleRemoveAnInjured = useCallback(
        (index) => {
            const selectedObj = claimVM.generalLob.injuryIncidents.value[index];
            showConfirm({
                title: messages.generalRemoveInjuredPopupTitle,
                message: translator(messages.generalRemoveInjuredPopupMessage, {
                    injuredIndex: index + 1
                }),
                confirmButtonText: messages.generalRemoveInjuredPopupRemoveButton,
                cancelButtonText: commonMessages.cancelModel
            }).then((results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }
                claimVM.generalLob.value.removeInjuryIncident(selectedObj);
                updateWizardData(claimVM);
                return true;
            }, _.noop);
        },
        [claimVM, translator, updateWizardData, showConfirm]
    );

    const getDeleteIcon = useCallback(
        (index) => {
            return (
                <div>
                    <Tooltip
                        placement="bottom"
                        content={translator(messages.generalTypeOfIncidentDelete)}
                    >
                        <IconButton
                            icon="mi-delete"
                            onClick={() => {
                                handleRemoveAnInjured(index);
                            }}
                        />
                    </Tooltip>
                </div>
            );
        },
        [handleRemoveAnInjured, translator]
    );

    const generateAddInjuredOverrides = useMemo(() => {
        const overrides = claimVM.generalLob.injuryIncidents.value.map((injured, index) => {
            return {
                [`addInjuredTitleText${index}`]: {
                    value: translator(messages.generalInjured, {
                        injuredIndex: index + 1
                    })
                },
                [`addInjuredTitleIcon${index}`]: {
                    value: getDeleteIcon(index)
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [claimVM.generalLob.injuryIncidents.value, getDeleteIcon, translator]);

    const handleSaveClaimData = useCallback(() => {
        setIsLoading(true);
        setLoadingMessage(translator(messages.fnolSaveDraftClaim));
        return FNOLService.saveFNOLDetails(claimVM.value, authHeader)
            .then((response) => {
                claimVM.value = response;
                return claimVM;
            })
            .catch(() => {
                showAlert({
                    title: messages.fnolSaveDraftErrorTitle,
                    message: messages.fnolSaveDraftErrorMessage,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
                return false;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [FNOLService, authHeader, claimVM, showAlert, translator]);

    const onNext = useCallback(() => {
        setIsLoading(true);
        const policyNumber = _.get(claimVM.value, 'policy.policyNumber');
        const isClaimNumberAvailable = _.get(claimVM.value, 'claimNumber');
        if (isClaimNumberAvailable) {
            return handleSaveClaimData();
        }
        setLoadingMessage(translator(messages.generalCreateDraftClaim));
        return FNOLService.getFNOLDetails(policyNumber, claimVM.value, authHeader)
            .then((response) => {
                claimVM.value = new Claim(response);
                return claimVM;
            })
            .catch(() => {
                showAlert({
                    title: messages.generalCreateDraftErrorTitle,
                    message: messages.generalCreateDraftErrorMessage,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
                return false;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [FNOLService, authHeader, claimVM, handleSaveClaimData, showAlert, translator]);

    const overrideProps = {
        '@field': {
            showOptional: true,
            phoneWide: {
                labelPosition: 'top'
            }
        },
        lossCause: {
            availableValues: setAvailableLossCauseValue,
            value: _.get(claimVM.value, 'lossCause')
        },
        typeOfIncidentPageLoader: {
            loaded: !isLoading,
            loadingMessage: translator(loadingMessage)
        },
        typeOfIncidentContainer: {
            visible: !isLoading
        },
        ...generateAddInjuredOverrides
    };

    const resolvers = {
        resolveCallbackMap: {
            handleAddAnInjured: handleAddAnInjured,
            handleRemoveAnInjured: handleRemoveAnInjured
        }
    };
    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrideProps);
        },
        [claimVM, overrideProps]
    );

    const { claimStatus } = state;

    const validateClaimStatus = useCallback(() => {
        return (
            !_.isNil(claimStatus)
            && claimStatus === translator({ id: 'typekey.ClaimState.draft', defaultMessage: 'Draft' })
        );
    }, [claimStatus, translator]);

    useEffect(() => {
        registerInitialComponentValidation(validateClaimStatus);
    }, [validateClaimStatus, registerInitialComponentValidation]);

    return (
        <WizardPage
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
            onNext={onNext}
            template={WizardPageTemplate}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                onValidationChange={onValidate}
                resolveValue={readValue}
            />
        </WizardPage>
    );
}

FNOLTypeOfIncidentPage.propTypes = wizardProps;
export default withRouter(withAuthenticationContext(FNOLTypeOfIncidentPage));
