import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './AddNotePopup.metadata.json5';

function AddNotePopup(props) {
    const {
        title,
        actionBtnLabel,
        cancelBtnLabel,
        notesVM,
        viewModelService,
        isOpen,
        onResolve,
        onReject
    } = props;
    const [getNotesVM, updateNotesVM] = useState(notesVM);
    const { isComponentValid, onValidate } = useValidation('AddNotePopup');

    const writeValue = useCallback(
        (value, path) => {
            const newNotesVM = viewModelService.clone(getNotesVM);
            _.set(newNotesVM, path, value);
            updateNotesVM(newNotesVM);
        },
        [getNotesVM, viewModelService]
    );

    const handleSave = useCallback(() => {
        onResolve(getNotesVM);
    }, [onResolve, getNotesVM]);

    return (
        <ModalNext isOpen={isOpen}>
            <ModalHeader title={title} />
            <ModalBody
                contentLayout={{
                    component: 'grid'
                }}
            >
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={getNotesVM}
                    onValidationChange={onValidate}
                    onValueChange={writeValue}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">
                    {cancelBtnLabel}
                </Button>
                <Button onClick={handleSave} disabled={!isComponentValid}>
                    {actionBtnLabel}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

AddNotePopup.propTypes = {
    notesVM: PropTypes.shape({}).isRequired,
    title: PropTypes.string.isRequired,
    actionBtnLabel: PropTypes.shape({}).isRequired,
    cancelBtnLabel: PropTypes.shape({}).isRequired,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired,
    onReject: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onResolve: PropTypes.func.isRequired
};

export default AddNotePopup;
