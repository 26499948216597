/* eslint-disable max-len*/
import React, {
    useState, useContext, useCallback, useEffect, useMemo
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { getConfigValue } from '@jutro/config';
import { useTranslator } from '@jutro/locale';
import { useValidation } from 'gw-portals-validation-react';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import Address from '../../models/Address';

import metadata from './LossLocation.metadata.json5';
import styles from './LossLocation.module.scss';
import messages from './LossLocation.messages';

function FNOLLossLocation(props) {
    const {
        id, onValidate, path: claimVM, updatePath: updateWizardData
    } = props;
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const [lossLocationAddress, updateLossLocationAddress] = useState('');
    const [policyAddressDropdown, updatePolicyAddressDropdown] = useState();
    const {
        isComponentValid,
        registerComponentValidation
    } = useValidation(id);
    const addressViewModel = viewModelService.create(
        _.get(claimVM.value, 'lossLocation') || new Address(),
        'cc',
        'edge.capabilities.address.dto.AddressDTO'
    );
    const [addressVM] = useState(addressViewModel);
    const [addressChange, setAddressChange] = useState({
        predefinedAddress: true,
        exactAddress: false,
        cityOnly: false,
        map: false
    });

    const [defaultMapAddress, setDefaultMapAddress] = useState([]);

    const handleAddressDropDownValueChange = useCallback(
        (addressIndex) => {
            const updatedLocations = claimVM.predefinedLossLocations.value.find((loss, index) => index === parseInt(addressIndex, 10));

            _.set(claimVM.value, 'optionalLossLocation.predefinedAddress', updatedLocations);
            updatePolicyAddressDropdown(addressIndex);
            addressVM.value = _.omit(updatedLocations, 'publicID');
            _.set(claimVM, 'lossLocation.value', updatedLocations);
            updateWizardData(claimVM);
        },
        [addressVM, claimVM, updateWizardData]
    );

    const handleValueChange = useCallback(
        (value) => {
            updateLossLocationAddress(value);

            const previouSelectedAddress = _.get(claimVM.value, 'selectedAddress');
            const isResetAddressValue = previouSelectedAddress !== value && previouSelectedAddress === 'predefinedAddress';

            _.set(claimVM.value, 'selectedAddress', value);

            const optionalLossLocationObject = _.get(claimVM.value, 'optionalLossLocation');
            const lossLocationValue = _.get(claimVM.value, 'lossLocation');

            if (
                (_.isEmpty(optionalLossLocationObject) && _.isEmpty(lossLocationValue))
                || (value === 'exactAddress' && isResetAddressValue)
                || (value === 'cityOnly' && isResetAddressValue)
            ) {
                const optionalLossLocation = {
                    predefinedAddress: new Address(),
                    others: new Address()
                };

                _.set(claimVM.value, 'optionalLossLocation', optionalLossLocation);
                addressVM.value = value === 'predefinedAddress'
                    ? optionalLossLocation.predefinedAddress
                    : optionalLossLocation.others;
                _.set(claimVM.value, 'lossLocation', addressVM.value);
            } else if (!_.isEmpty(lossLocationValue)) {
                addressVM.value = _.omit(lossLocationValue, 'publicID');
                _.set(claimVM.value, 'lossLocation', addressVM.value);

                if (value === 'predefinedAddress' && policyAddressDropdown) {
                    handleAddressDropDownValueChange(policyAddressDropdown);
                }
            } else {
                addressVM.value = value === 'predefinedAddress'
                    ? optionalLossLocationObject.predefinedAddress
                    : optionalLossLocationObject.others;
                _.set(claimVM.value, 'lossLocation', addressVM.value);
            }

            updateWizardData(claimVM);

            switch (value) {
                case 'predefinedAddress':
                    setAddressChange({
                        predefinedAddress: true,
                        exactAddress: false,
                        cityOnly: false,
                        map: false
                    });
                    break;
                case 'exactAddress':
                    setAddressChange({
                        predefinedAddress: false,
                        exactAddress: true,
                        cityOnly: true,
                        map: false
                    });
                    break;
                case 'cityOnly':
                    setAddressChange({
                        predefinedAddress: false,
                        exactAddress: true,
                        cityOnly: true,
                        map: false
                    });
                    break;
                case 'map':
                    setAddressChange({
                        predefinedAddress: false,
                        exactAddress: false,
                        cityOnly: false,
                        map: true
                    });
                    break;
                default:
                    setAddressChange({
                        predefinedAddress: true,
                        exactAddress: false,
                        cityOnly: false,
                        map: false
                    });
                    break;
            }
        },
        [addressVM, claimVM, handleAddressDropDownValueChange, policyAddressDropdown, updateWizardData]
    );

    useEffect(() => {
        registerComponentValidation(() => addressVM.aspects.valid && addressVM.aspects.subtreeValid);
    }, [addressVM.aspects.subtreeValid, addressVM.aspects.valid, registerComponentValidation]);

    const componentRendered = useCallback(
        (defaultAddress) => {
            const prevLossLocationType = _.get(claimVM.value, 'selectedAddress');

            updateLossLocationAddress(prevLossLocationType || defaultAddress);
            handleValueChange(prevLossLocationType || defaultAddress);
        },
        [claimVM, handleValueChange]
    );

    const setInitialAddress = useCallback(() => {
        if (
            (claimVM && claimVM.value.lobs.commercialAuto)
            || (claimVM && claimVM.value.lobs.personalAuto)
        ) {
            return componentRendered('exactAddress');
        }

        return componentRendered('predefinedAddress');
    }, [claimVM, componentRendered]);

    useEffect(() => {
        setInitialAddress();

        if (
            claimVM
            && lossLocationAddress === 'predefinedAddress'
            && claimVM.predefinedLossLocations.value.length === 1
        ) {
            handleAddressDropDownValueChange(0);
        }

        if (claimVM.value?.predefinedLossLocations) {
            setDefaultMapAddress(claimVM.value?.predefinedLossLocations[0]);
        }
        // All dependency is not required here. If we add all looping and hang the page.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewModelService, addressVM, lossLocationAddress]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    useEffect(() => function cleanup() {
        // Removing manually loaded google map api to fix the following error.
        // "You have included the Google Maps JavaScript API multiple times on this page.
        // This may cause unexpected errors."
        // Since we are using JUTRO MapArea component here we dont need manually loaded script.

        if (window.googleMapsAPILoadedManually && _.get(addressChange, 'map')) {
            window.google = null;
            window.googleMapsAPILoadedManually = false;
        }
    }, [addressChange]);

    const writeValue = useCallback(
        (value, path) => {
            _.set(addressVM, path, value);
            _.set(claimVM.value, 'lossLocation', addressVM.value);
            _.set(claimVM.value, 'optionalLossLocation.others', addressVM.value);
            updateWizardData(claimVM);
        },
        [claimVM, addressVM, updateWizardData]
    );

    const getAddressLineFields = useCallback(
        () => (
            <div className="addressLineFields">
                <p>{_.get(addressVM.value, 'addressLine1') || ''}</p>
                <p>{_.get(addressVM.value, 'addressLine2') || ''}</p>
                <p>{_.get(addressVM.value, 'addressLine3') || ''}</p>
            </div>
        ),
        [addressVM]
    );

    const getPreferredAddressDropDownValues = useCallback(() => {
        if (claimVM && claimVM.value.predefinedLossLocations) {
            const addressData = claimVM.value.predefinedLossLocations;

            return addressData.map((address, index) => {
                const addressJoin = [
                    address.addressLine1,
                    address.addressLine2,
                    address.addressLine3,
                    address.city,
                    address.postalCode,
                    address.state
                ];

                const addressArrayJoin = _.values(addressJoin)
                    .filter((value) => !!value)
                    .join(', ');
                const addressArrayJoinKey = {
                    code: _.toString(index),
                    name: addressArrayJoin
                };

                return addressArrayJoinKey;
            });
        }

        return [];
    }, [claimVM]);

    const handleUserLocationChange = useCallback(
        (address, path) => {
            addressVM.value = address;
            _.set(addressVM, 'country', address.countryCode);
            _.set(claimVM, path, address);
            updateWizardData(claimVM);
        },
        [updateWizardData, claimVM, addressVM]
    );

    const getAddressAvailableValues = useCallback(() => {
        const addressOptionsArray = [
            {
                code: 'predefinedAddress',
                name: translator(messages.fnolUseMyPolicyAddress)
            },
            {
                code: 'exactAddress',
                name: translator(messages.fnolSpecifyFullAddress)
            },
            {
                code: 'cityOnly',
                name: translator(messages.fnolKnowCity)
            },
            {
                code: 'map',
                name: translator(messages.fnolLocationMap)
            }
        ];

        if (
            (claimVM && claimVM.value.lobs.commercialAuto)
            || (claimVM && claimVM.value.lobs.personalAuto)
        ) {
            return addressOptionsArray.splice(1);
        }

        return addressOptionsArray;
    }, [claimVM, translator]);

    const overrideProps = useMemo(() => ({
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            phoneWide: {
                labelPosition: 'top'
            }
        },
        whereDidHappenRadioButton: {
            availableValues: getAddressAvailableValues(),
            value: lossLocationAddress
        },
        predefinedAddressContainer: {
            visible: addressChange.predefinedAddress && getAddressAvailableValues().length > 3
        },
        exactCityOnlyAddressContainer: {
            visible: addressChange.exactAddress && addressChange.cityOnly
        },
        selectAddress: {
            availableValues: getPreferredAddressDropDownValues(),
            value: policyAddressDropdown,
            visible:
                claimVM
                && claimVM.value.predefinedLossLocations
                && claimVM.value.predefinedLossLocations.length > 1
        },
        addressLines: {
            value: getAddressLineFields()
        },
        exactAddressLine1: {
            visible: lossLocationAddress === 'exactAddress' && addressChange.exactAddress
        },
        exactAddressLine2: {
            visible: lossLocationAddress === 'exactAddress' && addressChange.exactAddress
        },
        exactAddressLine3: {
            visible: lossLocationAddress === 'exactAddress' && addressChange.exactAddress
        },
        exactZipCode: {
            visible: lossLocationAddress === 'exactAddress' && addressChange.exactAddress
        },
        mapContainer: {
            visible: addressChange.map
        },
        googleMapPlacement: {
            onGoogleMapsApiKey: () => getConfigValue('GOOGLE_MAPS_API_KEY', appConfig.credentials.googleMapsApiKey),
            defaultValue: defaultMapAddress
        }
    }), [getAddressAvailableValues, lossLocationAddress, addressChange.predefinedAddress, addressChange.exactAddress, addressChange.cityOnly, addressChange.map, getPreferredAddressDropDownValues, policyAddressDropdown, claimVM, getAddressLineFields, defaultMapAddress]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleValueChange,
            onHandleAddressChange: handleAddressDropDownValueChange,
            onMapLocationChange: handleUserLocationChange
        }
    };

    const readValue = useCallback(
        (fieldId, path) => readViewModelValue(
            metadata.pageContent,
            addressVM,
            fieldId,
            path,
            overrideProps
        ),
        [addressVM, overrideProps]
    );

    if (_.isEmpty(addressVM)) {
        return null;
    }

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={addressVM}
            overrideProps={overrideProps}
            resolveValue={readValue}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            onValueChange={writeValue}
        />
    );
}

FNOLLossLocation.propTypes = {
    id: PropTypes.string.isRequired,
    path: PropTypes.shape({}).isRequired,
    onValidate: PropTypes.func.isRequired,
    updatePath: PropTypes.func.isRequired
};
export default FNOLLossLocation;
