import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { WizardPageTemplate } from 'gw-portals-wizard-components-ui';
import { withRouter } from 'react-router-dom';
import { useTranslator } from '@jutro/locale';
import { useValidation } from 'gw-portals-validation-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { Claim, fnolCommonMessages } from 'gw-capability-fnol-common-react';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import {
    CheckboxField, IconButton, useModal
} from '@jutro/components';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { DatatableUtil } from 'gw-portals-util-js';
import { messages as commonMessages } from 'gw-platform-translations';
import metadata from './FleetPage.metadata.json5';
import messages from '../../FNOLCA.messages';

function FNOLCAFleetPage(props) {
    const {
        showAlert
    } = useModal();

    const {
        wizardData: claimVM,
        updateWizardData,
        authHeader,
        history: {
            location: { state = {} }
        }
    } = props;
    const translator = useTranslator();
    const [isClaimVMInitialised, setClaimVMInitialised] = useState(false);
    const [vehicleList, updateVehicleList] = useState([]);
    const [vehicleFilter, updatevehicleFilter] = useState([]);
    const [ristUnitList, setRistUnitList] = useState([]);
    const [selectedFilter, updateSelectedFilter] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const { FNOLService } = useDependencies('FNOLService');
    const {
        onValidate,
        initialValidation,
        isComponentValid,
        registerInitialComponentValidation,
        registerComponentValidation
    } = useValidation('FNOLCAFleetPage');

    const getResponseData = useCallback((responseData, oldClaimVM) => {
        const vehicleData = responseData.lobs.commercialAuto;
        const vehicleInfo = vehicleData.vehicles.map((vehicle) => {
            const selected = oldClaimVM.policy.lobs.commercialAuto.vehicles.value.findIndex(
                (unit) => unit.policySystemId === vehicle.policySystemId
            ) > -1;
            return {
                vehicleLicenseNo: '',
                vehicleState: vehicle.state ? vehicle.state : '',
                vehicleCheckbox: vehicle,
                vehicleVin: vehicle.vin,
                vehicleYear: vehicle.year,
                vehicleModel: vehicle.model,
                vehicleId: vehicle.policySystemId,
                vehicleMake: vehicle.make,
                vehicleCheck: selected
            };
        });
        return vehicleInfo;
    }, []);

    const getVehicleFilter = useCallback(
        (vehicledatatable) => {
            let filteredArray;
            filteredArray = [translator(messages.CAAllModels)];
            vehicledatatable.map((vehicle) => {
                const makeAndModel = `${vehicle.vehicleMake} ${vehicle.vehicleModel}`;
                if (filteredArray.indexOf(makeAndModel) === -1) {
                    filteredArray = [...filteredArray, makeAndModel];
                }
                return filteredArray;
            });
            return filteredArray.map((key) => {
                return {
                    code: key,
                    name: key
                };
            });
        },
        [translator]
    );

    const getPolicySummaryRiskUnitsFromService = useCallback(() => {
        if (!claimVM.policy.lobs.commercialAuto) {
            claimVM.policy.lobs.value = { commercialAuto: { vehicles: [] } };
        }
        const oldClaimVM = _.cloneDeep(claimVM);
        const policyNumber = _.get(claimVM.value, 'policy.policyNumber');
        const lossDate = _.get(claimVM.value, 'lossDate');
        setIsLoading(true);
        return FNOLService.getPolicySummaryRiskUnits(policyNumber, lossDate, authHeader).then(
            (response) => {
                setIsLoading(false);
                setRistUnitList(response);
                const vehicledatatable = getResponseData(response, oldClaimVM);
                const filterResult = getVehicleFilter(vehicledatatable);
                updatevehicleFilter([...filterResult]);
                updateVehicleList([...vehicledatatable]);
            }
        );
    }, [FNOLService, authHeader, claimVM, getResponseData, getVehicleFilter]);

    useEffect(() => {
        if (!isClaimVMInitialised) {
            getPolicySummaryRiskUnitsFromService();
            setClaimVMInitialised(true);
        }
    }, [getPolicySummaryRiskUnitsFromService, isClaimVMInitialised]);

    const handleClose = useCallback(
        (vehicle) => {
            const index = vehicleList.indexOf(vehicle);
            vehicleList[index].vehicleCheck = false;
            updateVehicleList([...vehicleList]);
        },
        [vehicleList]
    );

    const getCloseButton = useCallback(
        (vehicles) => {
            return (
                <IconButton
                    id="closeButtonIcon"
                    onClick={() => handleClose(vehicles)}
                    icon="mi-close"
                />
            );
        },
        [handleClose]
    );

    const handleClick = useCallback(
        (value, vehicle) => {
            const selectedIndex = vehicleList.indexOf(vehicle);
            vehicleList[selectedIndex].vehicleCheck = value;
            updateVehicleList([...vehicleList]);
            const claimVehicleList = _.get(claimVM.value, 'policy.lobs.commercialAuto.vehicles');
            const riskUnitVehicleList = _.get(ristUnitList, 'lobs.commercialAuto.vehicles');
            const index = riskUnitVehicleList.findIndex(
                (unit) => unit.policySystemId === vehicle.vehicleId
            );
            if (value) {
                claimVehicleList.push(riskUnitVehicleList[index]);
                _.set(claimVM, 'policy.lobs.commercialAuto.vehicles.value', claimVehicleList);
            } else {
                const updatedList = claimVehicleList.splice(index, 0);
                _.set(claimVM, 'policy.lobs.commercialAuto.vehicles.value', updatedList);
            }
            updateWizardData(claimVM);
        },
        [claimVM, ristUnitList, updateWizardData, vehicleList]
    );

    const getSelectedValue = useCallback((item, index, { path: property }) => {
        return item[property];
    }, []);

    const getDataCell = useCallback((item, index, { path: property }) => {
        return item[property];
    }, []);

    const getbox = useCallback(
        (item, index) => {
            return (
                <CheckboxField
                    name={item.vehicleCheckbox.policySystemId}
                    id={item.vehicleCheckbox.policySystemId}
                    value={item.vehicleCheck}
                    onValueChange={(e) => handleClick(e, item, index)}
                />
            );
        },
        [handleClick]
    );

    const handleFilterChange = useCallback(
        (value) => {
            return updateSelectedFilter(value);
        },
        [updateSelectedFilter]
    );

    const onNext = useCallback(() => {
        const modifiedVehicleArray = _.filter(vehicleList, (item) => item.vehicleCheck);
        if (modifiedVehicleArray.length === 0) {
            return false;
        }
        const vehicles = _.get(claimVM.value, 'policy.lobs.commercialAuto.vehicles');
        const selectedVehicles = vehicles.filter((vehicle) => {
            return modifiedVehicleArray.some((modifiedVehicle) => {
                return vehicle.policySystemId === modifiedVehicle.vehicleId;
            });
        });
        _.set(claimVM.value, 'policy.lobs.commercialAuto.vehicles', selectedVehicles);
        setIsLoading(true);
        setLoadingMessage(translator(messages.caSaveDraftClaim));
        return FNOLService.saveFNOLDetails(claimVM.value, authHeader)
            .then((response) => {
                claimVM.value = new Claim(response);
                claimVM.lobs.commercialAuto.value.generateVehicleIncidents(
                    claimVM.lobs.commercialAuto.vehicles.value
                );
                return claimVM;
            })
            .catch(() => {
                showAlert({
                    title: messages.caSaveDraftErrorTitle,
                    message: messages.caSaveDraftErrorMessage,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
                return false;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [vehicleList, claimVM, translator, FNOLService, authHeader]);

    const filter = useCallback(() => {
        if (selectedFilter === translator(messages.CAAllModels) || selectedFilter === undefined) {
            return vehicleList;
        }
        return _.filter(
            vehicleList,
            ({ vehicleMake, vehicleModel }) => `${vehicleMake} ${vehicleModel}` === selectedFilter
        );
    }, [selectedFilter, translator, vehicleList]);

    const isVehicleList = useCallback(() => {
        if (vehicleList.length === 0) {
            return null;
        }
        return _.some(vehicleList, (item) => item.vehicleCheck);
    }, [vehicleList]);

    const overrideProps = {
        '@field': {
            phoneWide: {
                labelPosition: 'top'
            }
        },
        caFleetDataTable: {
            data: filter()
        },
        selectedGrid: {
            data: _.filter(vehicleList, (item) => item.vehicleCheck)
        },
        caFleetSearchAccordion: {
            visible: _.filter(vehicleList, (item) => item.vehicleCheck).length !== 0
        },
        additionalAccordionDetails: {
            title: `${_.filter(vehicleList, (item) => item.vehicleCheck).length} ${translator(messages.CAVehiclesSelected)}`
        },
        caFleetFilterModels: {
            availableValues: vehicleFilter,
            value: selectedFilter,
            onValueChange: handleFilterChange
        },
        fleetPageLoader: {
            loaded: !isLoading,
            loadingMessage: translator(loadingMessage)
        },
        caFleetDetails: {
            visible: !isLoading
        }
    };
    const resolvers = {
        resolveCallbackMap: {
            getSelectedValue: getSelectedValue,
            getbox: getbox,
            getCloseButton: getCloseButton,
            getDataCell: getDataCell,
            sortString: DatatableUtil.sortString,
            sortNumber: DatatableUtil.sortNumber,
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrideProps);
        },
        [claimVM, overrideProps]
    );

    const isClaimStatus = useCallback(() => {
        const { claimStatus } = state;
        return (
            isClaimVMInitialised
            && !_.isEmpty(claimStatus)
            && !_.isEmpty(_.get(claimVM, 'lobs.commercialAuto.value.vehicleIncidents'))
        );
    }, [claimVM, isClaimVMInitialised, state]);

    useEffect(() => {
        registerComponentValidation(isVehicleList);
        registerInitialComponentValidation(isClaimStatus);
    }, [
        isClaimStatus,
        isClaimVMInitialised,
        isVehicleList,
        registerComponentValidation,
        registerInitialComponentValidation,
        vehicleList
    ]);
    return (
        <WizardPage
            cancelLabel={translator(fnolCommonMessages.fnolSaveandExit)}
            template={WizardPageTemplate}
            onNext={onNext}
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}
FNOLCAFleetPage.propTypes = wizardProps;
export default withRouter(withAuthenticationContext(FNOLCAFleetPage));
