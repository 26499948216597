import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './ContactScheduleItem.metadata.json5';
import './ContactScheduleItem.messages';

function handleExistingPersonSelection(value, path, onValueChange, existingContacts) {
    const selectedContact = existingContacts[value];
    onValueChange(selectedContact, path);
}

function generateOverrides(
    hasExistingContacts,
    scheduleItem,
    onValueChange,
    modalData,
    pathToValue,
    shouldShowExistingContact,
    parentOverrides,
    isNew
) {
    const { existingContacts } = scheduleItem;

    return {
        ...parentOverrides,
        existingContactToggle: {
            visible: isNew && hasExistingContacts
        },
        existingContactContainer: {
            visible: isNew && hasExistingContacts && shouldShowExistingContact
        },
        existingContactSelect: {
            availableValues: existingContacts.map(({ person }, index) => ({
                name: `${person.lastName}, ${person.firstName}`,
                code: _.toString(index)
            })),
            onValueChange:
            (value, path) => handleExistingPersonSelection(
                value,
                path,
                onValueChange,
                existingContacts
            ),
            value: existingContacts.findIndex(
                (location) => _.isEqual(location, _.get(modalData, pathToValue))
            )
        },
        formContactContainer: {
            visible: !isNew || !hasExistingContacts || !shouldShowExistingContact
        }
    };
}

const setDefaultContactValues = (contact, defaultCountryCode) => {
    const newContact = contact;

    newContact.primaryAddress = newContact.primaryAddress || { country: defaultCountryCode };
    newContact.primaryAddress.country = newContact.primaryAddress.country || defaultCountryCode;
    newContact.person = newContact.person || {};

    return newContact;
};

function createContactVM(modalData, info, path, defaultCountryCode, viewModelService) {
    const { valueDeserializationClass } = info;
    const clonedModalData = _.cloneDeep(modalData);
    const modalContactObj = _.get(modalData, path, {});
    const contact = setDefaultContactValues(modalContactObj, defaultCountryCode);
    const contactVM = viewModelService.create(contact, 'pc', valueDeserializationClass);
    _.set(clonedModalData, path, contactVM);
    return clonedModalData;
}

export default function ContactScheduleItem({
    info,
    modalData,
    viewModelService,
    defaultCountryCode,
    scheduleItem,
    onValueChange,
    onValidate,
    parentOverrides,
    isNew
}) {
    const { id } = info;
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [onValidate, id, isComponentValid]);

    const path = 'itemData.PolicyContact.policyContactValue';
    const isExistingContactPath = `${path}.isExistingContact`;
    const hasExistingContacts = !_.isEmpty(scheduleItem.existingContacts);
    const shouldShowExistingContact = _.get(modalData, isExistingContactPath);

    const contactVM = createContactVM(modalData, info, path, defaultCountryCode, viewModelService);

    const countryPath = `${path}.primaryAddress.country`;
    const oldCountry = _.get(modalData, countryPath);
    const newCountry = _.get(contactVM, `${path}.primaryAddress.country.value.code`);
    if (oldCountry !== newCountry) {
        // If no country code is provided we use the default country code
        onValueChange(newCountry, countryPath);
    }

    if (isNew && hasExistingContacts && _.isUndefined(shouldShowExistingContact)) {
        // Initialise the modal with isExistingContact to true and default selected
        // contact to the first existingContact
        onValueChange(true, isExistingContactPath);
        handleExistingPersonSelection(0, path, onValueChange, scheduleItem.existingContacts);
    }

    const overrideProps = useMemo(
        () => generateOverrides(
            hasExistingContacts,
            scheduleItem,
            onValueChange,
            modalData,
            path,
            shouldShowExistingContact,
            parentOverrides,
            isNew
        ),
        [
            hasExistingContacts,
            scheduleItem,
            onValueChange,
            modalData,
            path,
            shouldShowExistingContact,
            parentOverrides,
            isNew
        ]
    );

    const readValue = useCallback(
        (elementId, elementPath) => {
            return readViewModelValue(
                metadata.componentContent,
                modalData,
                elementId,
                elementPath,
                overrideProps
            );
        },
        [modalData, overrideProps]
    );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={contactVM}
            overrideProps={overrideProps}
            onValueChange={onValueChange}
            onValidationChange={setComponentValidation}
            resolveValue={readValue}
        />
    );
}

ContactScheduleItem.propTypes = {
    info: PropTypes.shape({
        id: PropTypes.string
    }).isRequired,
    modalData: PropTypes.shape({}).isRequired,
    viewModelService: PropTypes.shape({}).isRequired,
    defaultCountryCode: PropTypes.string.isRequired,
    scheduleItem: PropTypes.shape({
        existingContacts: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    parentOverrides: PropTypes.shape({}).isRequired,
    isNew: PropTypes.bool.isRequired
};
