import { defineMessages } from 'react-intl';

export default defineMessages({
    cpDetailsOptionPlaceholder: {
        id: 'fnol.cp.views.details.Option',
        defaultMessage: '-- Option --'
    },
    cpDetailsClassCodePlaceholder: {
        id: 'fnol.cp.views.details.Select Class Code',
        defaultMessage: '-- Select Class Code --'
    },
    cpDetailsRoofCoveringPlaceholder: {
        id: 'fnol.cp.views.details.Select Roof Covering Materials',
        defaultMessage: '-- Select Roof Covering Materials --'
    },
    cpDetailsExteriorWallPlaceholder: {
        id: 'fnol.cp.views.details.Select Exterior Wall Material',
        defaultMessage: '-- Select Exterior Wall Material --'
    },
    cpDetailsOccupancyPlaceholder: {
        id: 'fnol.cp.views.details.Select Type of Occupancy',
        defaultMessage: '-- Select Type of Occupancy --'
    },
    cpDetailsLossAreaPlaceholder: {
        id: 'fnol.cp.views.details.Select Loss Area',
        defaultMessage: '-- Select Loss Area --'
    }
});
