import React, { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useTranslator } from '@jutro/locale';
import { FNOLLossLocation, fnolCommonMessages } from 'gw-capability-fnol-common-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { WizardPageTemplate } from 'gw-portals-wizard-components-ui';
import { useValidation } from 'gw-portals-validation-react';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { isCapabilityEnabled } from 'gw-portals-config-js';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import metadata from './CollisionDetailsPage.metadata.json5';
import './CollisionDetailsPage.messages';
import wizardConfig from '../../../config/fnol-pa-wizard-config.json5';

function FNOLPACollisionDetailsPage(props) {
    const {
        wizardData: claimVM,
        updateWizardData,
        changeNextSteps,
        currentStepIndex,
        history: {
            location: { state: claimDetails = {} }
        }
    } = props;
    const { steps: wizardSteps } = wizardConfig;
    const { capabilitiesConfig } = appConfig;
    const translator = useTranslator();
    const [showPropertyIncident, updateShowPropertyIncident] = useState(false);
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerInitialComponentValidation
    } = useValidation('CollisionDetailsPage');

    const onHandlePropertyDamageChange = (value, path) => {
        updateShowPropertyIncident(value);
        _.set(claimVM, path, value);
        updateWizardData(claimVM);
    };

    useEffect(() => {
        let nextSteps = wizardSteps.slice(currentStepIndex + 1, wizardSteps.length);
        if (!isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'vendor' })) {
            nextSteps = nextSteps.filter((step) => step.path !== '/type-of-repair');
        }
        changeNextSteps(nextSteps);
        // Call once when page is mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true
        },
        wasAnyPropertyDamaged: {
            value: showPropertyIncident
        },

        describePropertyDamage: {
            visible: showPropertyIncident
        },
        lossLocationComponent: {
            path: !_.isUndefined(claimVM) ? claimVM : null,
            updatePath: updateWizardData
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onHandlePropertyDamageChange: onHandlePropertyDamageChange,
            onValidate: onValidate
        },
        resolveComponentMap: {
            losslocationcomponent: FNOLLossLocation
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrideProps);
        },
        [claimVM, overrideProps]
    );

    const isClaimStatus = useCallback(() => {
        const { claimStatus } = claimDetails;
        return !_.isEmpty(claimStatus);
    }, [claimDetails]);

    useEffect(() => {
        registerInitialComponentValidation(isClaimStatus);
    }, [registerInitialComponentValidation, isClaimStatus]);

    return (
        <WizardPage
            cancelLabel={translator(fnolCommonMessages.fnolSaveandExit)}
            skipWhen={initialValidation}
            template={WizardPageTemplate}
            disableNext={!isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={onValidate}
                componentMap={resolvers.resolveComponentMap}
                resolveValue={readValue}
            />
        </WizardPage>
    );
}

FNOLPACollisionDetailsPage.propTypes = wizardProps;
FNOLPACollisionDetailsPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};
export default withRouter(FNOLPACollisionDetailsPage);
